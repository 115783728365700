import React, {
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useState,
  useRef,
} from "react";
import {
  useTable,
  useGlobalFilter,
  useFilters,
  usePagination,
} from "react-table";
import { Link, useHistory } from "react-router-dom";

import { COLUMNS } from "./Columns";
import { GlobalFilter } from "./GlobalFilter";
import Button from "react-bootstrap/Button";
import Modale from "../components/sis/Modale";
//import './table.css';
import "./filtering.css";
import {
  getAllUsuario,
  getUserCredentials,
  signUp,
} from "../../api/usuarioApi";
import Swal from "sweetalert2";
import { TYPE_USER } from "../../utils/Const";
import { ButtonGroup } from "react-bootstrap";
import { exportToExcel } from 'react-json-to-excel';

const showReducer = (curShow, state) => {
  switch (state.type) {
    case "SHOW":
      return { isShow: true, typeModal: state.typeModal };
    case "END":
      return { ...curShow, isShow: false };
  }
};

export const Usuarios = () => {
  const [isHover, setIsHover] = useState(false);
  const handleMouseEnter = () => {
    setIsHover(true);
  };

  const handleMouseLeave = () => {
    setIsHover(false);
  };
  const [listUsuario, setListUsuario] = useState([]);
  const columns = useMemo(() => COLUMNS, []);
  const [data, setdata] = useState([]);
  const history = useHistory();
  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageSize: 10, pageIndex: 0 },
    },
    useFilters,
    useGlobalFilter,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    state,
    page,
    gotoPage,
    pageCount,
    pageOptions,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    setGlobalFilter,
  } = tableInstance;

  const { globalFilter, pageIndex } = state;

  const [reducer, dispatchReducer] = useReducer(showReducer, {
    isShow: false,
    x: 0,
  });

  const handleShow3 = () => {
    dispatchReducer({ type: "SHOW", typeModal: "USUARIO" });
  };
  const onCloseModal = () => {
    dispatchReducer({ type: "END" });
  };

  const onReturnUsuarioData = async (data) => {
    try {
      Swal.fire({
        title: "Guardando",
        allowOutsideClick: false,
        showCancelButton: false,
        showConfirmButton: false,
      });
      const result = await signUp(data);
      if (result) {
        Swal.fire({
          title: "Usuario",
          html: `Se guardo correctamente el usuaro`,
          icon: "success",
        });
        const listResult = await getAllUsuario();
        setdata([...listResult]);
        dispatchReducer({ type: "END" });
      }
      Swal.close();
    } catch (err) {
      Swal.close();
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: `${err}`,
      });
    }
  };

  const assigmentValue = useCallback(async () => {
    try {

      const resultUser = await getUserCredentials();
      if (resultUser.IdTipoUsuario === TYPE_USER.ADMINISTRACION.Id || resultUser.IdTipoUsuario === TYPE_USER.OPERATIVA.Id) {
        const result = await getAllUsuario();
        for (let i = 0; i < result.length; i++) {
          if (result[i].IdTipoUsuario == 2) {
            result[i].IdTipoUsuario = "Enfermeria";
          }
          if (result[i].IdTipoUsuario == 3) {
            result[i].IdTipoUsuario = "logistica";
          }
        }
        setdata([...result]);

      }
      else {
        history.push("/");
      }

    } catch (err) {

      console.log(err);
    }
  });

  useEffect(() => {
    assigmentValue();
  }, []);
  const tableRef = useRef(null);


  return (
    <div className="card">
      <div className="card-header">
        <h4 className="card-title">Cuentas</h4>
        <ButtonGroup>
          <Button onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave} style={{ backgroundColor: isHover ? '#207948' : '#1D6F42', borderColor: "#1D6F42" }} onClick={() => exportToExcel(data, 'Usuarios del servicio')}>
            Descargar Excel
          </Button>
          <Button variant="primary" onClick={handleShow3}>
            Agregar Cuenta
          </Button>

        </ButtonGroup>
        {reducer.isShow && (
          <Modale
            estado={reducer.isShow}
            typeModal={reducer.typeModal}
            onCloseModal={onCloseModal}
            onReturnValue={onReturnUsuarioData}
          />
        )}
      </div>

      <div className="card-body">
        <div className="table-responsive">
          <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
          <table
            {...getTableProps()}
            className="table dataTable display"
            ref={tableRef}
          >
            <thead>
              {headerGroups.map((headerGroup, index1) => (
                <tr {...headerGroup.getHeaderGroupProps()} key={index1}>
                  {headerGroup.headers.map((column) => (
                    <th {...column.getHeaderProps()}>
                      {column.render("Header")}
                      {column.canFilter ? column.render("Filter") : null}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()} >
              {page.map((row, index2) => {
                prepareRow(row);

                return (
                  <tr {...row.getRowProps()} key={index2}  >
                    {row.cells.map((cell, index3) => {

                      return (
                        <td  {...cell.getCellProps()} key={index3} style={{
                          padding: '10px',

                        }}

                        >
                          <Link
                            to={{
                              pathname: "/fichausuario",
                              state: { id: row.values.IdUsuario },
                            }}
                          >
                            {" "}
                            {cell.render("Cell")}{" "}
                          </Link>
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
          <div className="d-flex justify-content-between">
            <span>
              Page{" "}
              <strong>
                {pageIndex + 1} of {pageOptions.length}
              </strong>
              {""}
            </span>
            <span className="table-index">
              Go to page :{" "}
              <input
                type="number"
                className="ml-2"
                defaultValue={pageIndex + 1}
                onChange={(e) => {
                  const pageNumber = e.target.value
                    ? Number(e.target.value) - 1
                    : 0;
                  gotoPage(pageNumber);
                }}
              />
            </span>
          </div>
          <div className="text-center mb-3">
            <div className="filter-pagination  mt-3">
              <button
                className=" previous-button"
                onClick={() => gotoPage(0)}
                disabled={!canPreviousPage}
              >
                {"<<"}
              </button>

              <button
                className="previous-button"
                onClick={() => previousPage()}
                disabled={!canPreviousPage}
              >
                Previous
              </button>
              <button
                className="next-button"
                onClick={() => nextPage()}
                disabled={!canNextPage}
              >
                Next
              </button>
              <button
                className=" next-button"
                onClick={() => gotoPage(pageCount - 1)}
                disabled={!canNextPage}
              >
                {">>"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Usuarios;
