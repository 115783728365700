import { Button, Modal } from "react-bootstrap";
import React, { useState } from "react";
const ModalLiquidar = (props) => {
  const { estado, onCloseModal, onReturnValue } = props;
  const [formData3, setValue] = useState([]);
  const handleSubmit2 = (event) => {
    event.preventDefault();
    formData3.estado = formData3.estado? formData3.estado:"ENVIADO"
    onReturnValue(formData3);
  };
  return (
    <>
      <Modal show={estado} onHide={onCloseModal} size="m">
        <Modal.Header closeButton>
          <Modal.Title>Liquidar</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form  >

            <div className="form-group mb-2 row">
              <label className="col-form-label">
                Estado
                <span className="text-danger">*</span>
              </label>
              <div className="">
                <select     className="form-control" onChange={(e) => {
                    setValue({ ...formData3, estado: e.target.value });
                  }}>
                  <option value="ENVIADO">Enviado</option>
                  <option value="LIQUIDADO">Liquidado</option>
                  <option value="CIERRE">Cerrado</option>
                </select>
              </div>
            </div>
           
            <div className="form-group mb-2 row">
              <label className="col-form-label">
                Factura Numero
                <span className="text-danger">*</span>
              </label>
              <div className="">
                <input
                  type="text"
                  className="form-control"
                  required
                  onChange={(e) => {
                    setValue({ ...formData3, facturanumero: e.target.value });
                  }}
                />
              </div>
            </div>
            <div className="form-group mb-2 row">
              <label className="col-form-label">
                Monto Factura
                <span className="text-danger">*</span>
              </label>
              <div className="">
                <input
                  type="number"
                  className="form-control"
                  required
                  onChange={(e) => {
                    setValue({ ...formData3, montoliquidado: e.target.value });
                  }}
                />
              </div>
            </div>
          
            <div className="form-group mb-2 row">
              <label className="col-form-label">
                Obs.
                <span className="text-danger">*</span>
              </label>
              <div className="">
                <textarea
                  type="text"
                  className="form-control"
                  required
                  onChange={(e) => {
                    setValue({ ...formData3, obs: e.target.value });
                  }}
                />
              </div>
            </div>


          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={onCloseModal}>
            Cerrar
          </Button>
          <Button onClick={handleSubmit2} form="userform">
            Guardar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ModalLiquidar;
