import { Button, Modal } from "react-bootstrap";
import React, { useState } from "react";
const ModalFeriado = (props) => {

    const { estado, onCloseModal, onReturnValue, rowdata } = props;
    const [formData3,setValue] = useState(rowdata);
    const [total,settotal]= useState(((formData3.CostoHoraProfesional*formData3.horasferiado)*formData3.porcentajeferiado/100))
    const [total2,settotal2]= useState(((formData3.CostoHoraEmpresa*formData3.horasferiado)*formData3.porcentajeferiado/100))
    const CalcularHorasTotal = (e) => {
        setValue({
          ...formData3,
          [e.target.name]: e.target.value,
        })

       let total1,total2
       if (e.target.name==="horasferiado")
       {
           let fer = formData3.CostoHoraProfesional*e.target.value
           let fer2 =  formData3.CostoHoraEmpresa*e.target.value
           total1=fer*formData3.porcentajeferiado/100
           total2=fer2*formData3.porcentajeferiado/100
       }else
       {
           let fer = formData3.CostoHoraProfesional*formData3.horasferiado
           let fer2 =  formData3.CostoHoraEmpresa*formData3.horasferiado
           total1=(fer*e.target.value/100)
           total2=(fer2*e.target.value/100)
       }
       settotal(Math.round(total1))
       settotal2(Math.round(total2))
      };
    const handleSubmit2 = (event) => {
        event.preventDefault();
        onReturnValue(formData3.horasferiado,formData3.porcentajeferiado,formData3.IdLegajoEnfermeria);
    };
    return (
        <>
            <Modal show={estado} onHide={onCloseModal} size="m">
                <Modal.Header closeButton>
                    <Modal.Title>Monto Feriado</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form  >

                        <div className="form-group mb-2 row">
                            <label className="col-form-label">
                                Horas
                                <span className="text-danger">*</span>
                            </label>
                            <div className="">
                                <input
                                    type="number"
                                    className="form-control"
                                    name="horasferiado"
                                    value={formData3.horasferiado}
                                    onChange={(e) => {
                                        CalcularHorasTotal(e);
                                      }}
                                />
                            </div>
                        </div>
                        <div className="form-group mb-2 row">
                            <label className="col-form-label">
                                Porcentaje
                                <span className="text-danger">*</span>
                            </label>
                            <div className="">
                                <input
                                    type="number"
                                    className="form-control"
                                    name="porcentajeferiado"
                                    value={formData3.porcentajeferiado}
                                    onChange={(e) => {
                                        CalcularHorasTotal(e);
                                      }}
                                />
                            </div>
                        </div>
                        <div className="form-group mb-2 row">
                            <label className="col-form-label">
                                Total Honorario
                                <span className="text-danger">*</span>
                            </label>
                            <div className="">
                                <input
                                    type="number"
                                    className="form-control"
                                    disabled
                                    value={total}
                                />
                            </div>
                        </div>
                        <div className="form-group mb-2 row">
                            <label className="col-form-label">
                                Total Tarifa
                                <span className="text-danger">*</span>
                            </label>
                            <div className="">
                                <input
                                    type="number"
                                    className="form-control"
                                    disabled
                                    value={total2}
                                />
                            </div>
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={onCloseModal}>
                        Cerrar
                    </Button>
                    <Button onClick={handleSubmit2} form="userform">
                        Guardar
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default ModalFeriado;
