import React, {
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from "react";

import {
  useTable,
  useGlobalFilter,
  useFilters,
  usePagination,
} from "react-table";
import { COLUMNS, CLIENTE_COLUMNS } from "./Columns";
import { GlobalFilter } from "./GlobalFilter";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Modale from "../components/sis/Modale";
import { Link, useHistory } from "react-router-dom";
import { exportToExcel } from "react-json-to-excel";
//import './table.css';
import "./filtering.css";
import { getAllPaciente, insertPaciente } from "../../api/pacienteApi";
import Swal from "sweetalert2";
import { getUserCredentials } from "../../api/usuarioApi";
import { TYPE_USER } from "../../utils/Const";

const showReducer = (curShow, state) => {
  switch (state.type) {
    case "SHOW":
      return {
        isShow: true,
        typeModal: state.typeModal,
        onReturnValue: state.onReturnValue,
      };
    case "END":
      return { ...curShow, isShow: false };
  }
};

export const Clientes = () => {
  const [reducer, dispatchReducer] = useReducer(showReducer, {
    isShow: false,
    typeModal: null,
    onReturnValue: null,
  });
  const [isHover, setIsHover] = useState(false);
  const handleMouseEnter = () => {
    setIsHover(true);
  };

  const handleMouseLeave = () => {
    setIsHover(false);
  };
  const [data, setdata] = useState([]);
  const history = useHistory();
  const assigmentValue = useCallback(async () => {
    try {
      const resultUser = await getUserCredentials();
      if (resultUser.IdTipoUsuario === TYPE_USER.ADMINISTRACION.Id || resultUser.IdTipoUsuario === TYPE_USER.OPERATIVA.Id )
      {
        const result = await getAllPaciente();
        setdata([...result]);
      }
      else{
        history.push("/");
    }
        
    } catch (err) {
      console.log(err);
    }
  });

  useEffect(() => {
    assigmentValue();
  }, []);

  const handleShow4 = () => {
    dispatchReducer({ type: "SHOW", typeModal: "CLIENTE" });
  };
  const onCloseModal = () => {
    dispatchReducer({ type: "END" });
  };

  const columns = useMemo(() => CLIENTE_COLUMNS, []);

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
    },
    useFilters,
    useGlobalFilter,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    state,
    page,
    gotoPage,
    pageCount,
    pageOptions,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    setGlobalFilter,
  } = tableInstance;

  const { globalFilter, pageIndex } = state;

  const onReturnDataCliente = async (data) => {
    Swal.fire({
      title: "Guardando",
      allowOutsideClick: false,
      showCancelButton: false,
      showConfirmButton: false,
    });
    dispatchReducer({ type: "END" });
    try {
      const result = await insertPaciente(data);

      if (result) {
        if (result) {
          Swal.fire({
            title: "Cliente Cargado",
            html: `Se cargo correctalmente el cliente`,
            icon: "success",
          });
          const listResult = await getAllPaciente();
          setdata([...listResult]);
          Swal.close();
        }
      }
    } catch (err) {
      Swal.close();
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: `${err}`,
      });
    }
  };

  return (
    <>
      <div className="card">
        <div className="card-header">
          <h4 className="card-title">Usuarios del servicio</h4>
          <ButtonGroup aria-label="Basic example">
            <Button
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              style={{
                backgroundColor: isHover ? "#207948" : "#1D6F42",
                borderColor: "#1D6F42",
              }}
              onClick={() => exportToExcel(data, "Usuarios del servicio")}
            >
              Descargar Excel
            </Button>
            <Button variant="primary" onClick={handleShow4}>
              Agregar Asuario
            </Button>
          </ButtonGroup>

          {reducer.isShow && (
            <Modale
              estado={reducer.isShow}
              typeModal={reducer.typeModal}
              onCloseModal={onCloseModal}
              onReturnValue={onReturnDataCliente}
            />
          )}
        </div>

        <div className="card-body">
          <div className="table-responsive">
            <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
            <table {...getTableProps()} className="table dataTable display">
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th {...column.getHeaderProps()}>
                        {column.render("Header")}
                        {column.canFilter ? column.render("Filter") : null}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()} className="">
                {page.map((row) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell) => {
                        return (
                          <td
                            {...cell.getCellProps()}
                            style={{
                              padding: "10px",
                            }}
                          >
                            <Link
                              to={{
                                pathname: "/Fichaclientes",
                                state: { id: row.values.IdPacientes },
                              }}
                            >
                              {" "}
                              {cell.render("Cell")}{" "}
                            </Link>
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <div className="d-flex justify-content-between">
              <span>
                Page{" "}
                <strong>
                  {pageIndex + 1} of {pageOptions.length}
                </strong>
                {""}
              </span>
              <span className="table-index">
                Go to page :{" "}
                <input
                  type="number"
                  className="ml-2"
                  defaultValue={pageIndex + 1}
                  onChange={(e) => {
                    const pageNumber = e.target.value
                      ? Number(e.target.value) - 1
                      : 0;
                    gotoPage(pageNumber);
                  }}
                />
              </span>
            </div>
            <div className="text-center mb-3">
              <div className="filter-pagination  mt-3">
                <button
                  className=" previous-button"
                  onClick={() => gotoPage(0)}
                  disabled={!canPreviousPage}
                >
                  {"<<"}
                </button>

                <button
                  className="previous-button"
                  onClick={() => previousPage()}
                  disabled={!canPreviousPage}
                >
                  Previous
                </button>
                <button
                  className="next-button"
                  onClick={() => nextPage()}
                  disabled={!canNextPage}
                >
                  Next
                </button>
                <button
                  className=" next-button"
                  onClick={() => gotoPage(pageCount - 1)}
                  disabled={!canNextPage}
                >
                  {">>"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Clientes;
