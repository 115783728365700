import React, { useEffect, useState, useReducer } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { useCallback } from "react";
import Swal from "sweetalert2";
import { ButtonGroup, Button } from "react-bootstrap";
import ModalLiquidar from "./ModalLiquidar";
import ModalEditarLegajo from "./ModalEditarLegajo"
import ModalFeriado from "./ModalFeriado"
import ModalLegajo from "./ModalLegajo"
import ModalGastos from "./ModalGastos"
import genPDF from "./pdfWriter";
import genPDF2 from "./pdfWriter2";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import pdfrecibo from "./pdfrecibo"
import { CiEdit } from "react-icons/ci";
import { CiCalendarDate } from "react-icons/ci";
import { IoMdTimer } from "react-icons/io";
import { TbUserDollar } from "react-icons/tb";
import { LiaUserClockSolid } from "react-icons/lia";
import {
  getliquidacionbyid,
  getlegajosbyliquidacion,
  liquidarliquidacion,
  InsertRecibo,
  getidrecibo,
  AddLegajo,
  EditLegajo,
  EditFeriado,
  LegajoHoraExtra,
  AddGasto,
  EditGasto,
  getgastosbyliquidacion
} from "../../../api/marcacionApi";
import { Table } from "react-bootstrap";
import { getPacientesById } from "../../../api/pacienteApi";
import { getUsuarioById } from "../../../api/usuarioApi";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import ModalHoraExtra from "../../components/sis/modalsesiones/Agregarhoraextra";
import ModalEditGastos from "./ModalEditGastos"
import moment from "moment";
const showReducer = (curShow, state) => {
  switch (state.type) {
    case "SHOW":
      return {
        isShow: true,
      };
    case "SHOW2":
      return {
        isShow2: true,
      };
    case "SHOW3":
      return {
        isShow3: true,
      };
    case "SHOW4":
      return {
        isShow4: true,
      };
    case "SHOW5":
      return {
        isShow5: true,
      };
    case "SHOWGASTOS":
      return {
        isShowGASTOS: true,
      };
    case "SHOWEDITGASTOS":
      return {
        isShowEditGASTOS: true,
      };
    case "END":
      return {
        ...curShow,
        isShow: false,
        isShow2: false,
        isShow3: false,
        isShow4: false,
        isShow5: false,
        isShowGASTOS: false,
        isShowEditGASTOS: false,
      };
  }
};

const FichaLiquidacion = () => {
  const location = useLocation();
  const { id, nombrepaciente } = location.state;
  const [liquidaciondata, getliquidaciondata] = useState([]);
  const [LiquidacionSinFiltro, setLiquidacionSinFiltro] = useState([])
  const [SumaHoras, setSumaHoras] = useState(0)
  const [SumaMonto, setSumaMonto] = useState(0)
  const [MontoGasto, setMontoGasto] = useState("")
  const [gastos, setgastos] = useState([])
  const [isliqui, setisliqui] = useState();
  const [editdata, seteditdata] = useState([""])
  const [Liquidacion, setliquidacion] = useState([""])
  const [filtrogastos, setfiltrogastos] = useState([])
  const [confirmed, setConfirmed] = useState(false)
  const [listaprofesionales, setlista] = useState([]);
  const [rowdata, setrowdata] = useState();
  const [prev,setPrev]=useState(true)
  const [Paciente, setPaciente] = useState()
  const history = useHistory();
  const [reducer, dispatchReducer] = useReducer(showReducer, {
    isShow: false,
    isShow2: false,
  });
  const formatTime = (value) => {
    if (value != undefined) {
      return value.split(".")[0];
    }

  };
  //Modales
  const showModalHoraExtra = (data) => {
    setrowdata(data);
    dispatchReducer({ type: "SHOW5" });
  };
  const handleShow = () => {
    dispatchReducer({ type: "SHOW" });
  };
  const handleShow2 = (row) => {
    if (row.estaConfirmado.data[0] === 0) {
      setConfirmed(false)
    } else {
      setConfirmed(true)
    }
    if (row.isPreview==="SI")
    {
      setPrev(false)
    }else
    {
      setPrev(true)
    }
    setrowdata(row)
    dispatchReducer({ type: "SHOW2" });
  };
  const handleShowFeriado = (row) => {
    setrowdata(row)
    dispatchReducer({ type: "SHOW4" });
  };
  const handleShow3 = () => {
    dispatchReducer({ type: "SHOW3" });
  };
  const handleSAddGastos = () => {
    dispatchReducer({ type: "SHOWGASTOS" });
  };

  //Modals

  function horasalida(fecha, hora, horas) {
    let fechatotal = new Date(`${fecha} ${hora}`);
    const hoursToMs = fechatotal.getTime() + horas * 60 * 60 * 1000;

    fechatotal.setTime(hoursToMs);
    const showTime =
      addZeroBefore(fechatotal.getHours()) +
      ":" +
      addZeroBefore(fechatotal.getMinutes()) +
      ":" +
      addZeroBefore(fechatotal.getSeconds());

    return `${showTime}`;
  }
  function addZeroBefore(n) {
    return (n < 10 ? "0" : "") + n;
  }
  const proftabs = (idprofesional, keyx) => {
    return (
      <Tab eventKey={idprofesional.id} title={idprofesional.name}>
        <Table
          id="my-table"
          responsive
        >
          <thead className="thead-primary">
            <tr key={keyx}>
              <th scope="col">#</th>
              <th scope="col">Fecha Entrada</th>
              <th scope="col">Hora Entrada</th>
              <th scope="col">Fecha Salida</th>
              <th scope="col">Hora Salida</th>
              <th scope="col">Horas</th>
              <th scope="col">Honorario</th>
              <th scope="col">Enfermero</th>
            </tr>
          </thead>
          <tbody>
            {Liquidacion.map((item2, index5) => {

              let horas = item2.horascalculadas + Number(Number(item2.horashoraextra).toFixed(2));
              let honorario =
                item2.CostoHoraProfesional * horas + ((item2.CostoHoraProfesional * item2.horasferiado) * item2.porcentajeferiado / 100);
              return (
                <>
                  <tr key={index5}>
                    <td>{index5}</td>
                    <td>{item2.Fecha}</td>
                    <td>{formatTime(item2.HoraEntrada)}</td>
                    <td>{item2.FechaSalida}</td>
                    <td>
                      {horasalida(
                        item2.Fecha,
                        item2.HoraEntrada,
                        item2.horascalculadas + item2.horashoraextra
                      )}
                    </td>
                    <td>{horas.toFixed(2)}</td>
                    <td>{Math.round(honorario).toLocaleString('es')}</td>
                    <td>{item2.Usuario}</td>
                  </tr>
                </>
              );
            })}
          </tbody>
          <tfoot>
            <tr>
              <td colSpan={5}>Total</td>
              <td>{SumaHoras.toFixed(2)}</td>
              <td>{SumaMonto.toLocaleString('es')}</td>
            </tr>
          </tfoot>
        </Table>
        <Button className="ml-2" onClick={descargarpdfprof}>Descargar PDF</Button>
        <Button onClick={descargarrecivo}>Descargar Recibo</Button>
      </Tab>
    );
  };
  const onCloseModal = () => {
    dispatchReducer({ type: "END" });
  };
  const Liquidar = async (data) => {
    dispatchReducer({ type: "END" });
    
    try {
      let idliquidacion = id;
      const result = await liquidarliquidacion({ ...data, idliquidacion });
      if (result) {
        Swal.fire({
          title: "Datos de la liquidacion",
          html: `Actualizados`,
          icon: "success",
        });
        assigmentValue();
      }
    } catch (err) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: `${err}`,
      });
    }
    //liquidar
  };

    // legajos 
  const NewLegajo = async (data) => {
    dispatchReducer({ type: "END" });
    try {
      Swal.fire({
        title: "Generando",
        didOpen: () => {
          Swal.showLoading();
        },
      });
      let idliquidacion = id;
      let idPaciente = Liquidacion[0].IdPaciente
      let paciente = nombrepaciente
      await AddLegajo({ ...data, idliquidacion, idPaciente, paciente });
      let data1 = await getlegajosbyliquidacion(id);
      setLiquidacionSinFiltro(data1)
      const result = data1.map((number) => {
        const container = {};

        container.id = number.IdUsuario;
        container.name = number.Usuario;

        return container;
      });

      const listprof = new Map(result.map((c) => [c.id, c])); // los profesionales
      const uniquepprof = [...listprof.values()];

      // sumas liquidacion tarifa y liquidacion honorario


      setlista(uniquepprof);
      if (key === "Principal") {
        filtroprincipal(data1,filtrogastos)
      }
      else if (key === "Principal2") {
        filtrohonorario(data1,filtrogastos)
      } else {
        filtroProf(key,data1,filtrogastos)
      }
      Swal.close()
    } catch (err) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: `${err}`,
      });
    }
    //liquidar
  };
  const EditFeriadoFunc = async (horasferiado, porcentajeferiado, IdLegajoEnfermeria) => {
    dispatchReducer({ type: "END" });
    try {
      Swal.fire({
        title: "Generando",
        didOpen: () => {
          Swal.showLoading();
        },
      });
      await EditFeriado({ horasferiado, porcentajeferiado, IdLegajoEnfermeria });
      let data1 = await getlegajosbyliquidacion(id);
      setLiquidacionSinFiltro(data1)
      if (key === "Principal") {
        filtroprincipal(data1,filtrogastos)
      }
      else if (key === "Principal2") {
        filtrohonorario(data1,filtrogastos)
      } else {
        filtroProf(key,data1,filtrogastos)
      }
      Swal.close()
    } catch (err) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: `${err}`,
      });
    }
    //liquidar
  };
  const EditLegajoFunc = async (data) => {
    dispatchReducer({ type: "END" });
    try {
      Swal.fire({
        title: "Generando",
        didOpen: () => {
          Swal.showLoading();
        },
      });
      await EditLegajo(data);
      let data1 = await getlegajosbyliquidacion(id);
      setLiquidacionSinFiltro(data1)
      const result = data1.map((number) => {
        const container = {};

        container.id = number.IdUsuario;
        container.name = number.Usuario;

        return container;
      });

      const listprof = new Map(result.map((c) => [c.id, c])); // los profesionales
      const uniquepprof = [...listprof.values()];

      // sumas liquidacion tarifa y liquidacion honorario


      setlista(uniquepprof);
      if (key === "Principal") {
        filtroprincipal(data1,filtrogastos)
      }
      else if (key === "Principal2") {
        filtrohonorario(data1,filtrogastos)
      } else {
        filtroProf(key,data1,filtrogastos)
      }
      Swal.close()
    } catch (err) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: `${err}`,
      });
    }
    //liquidar
  };
  const FuncionHoraExtra = async (data, data2) => {
    dispatchReducer({ type: "END" });
    try {
      Swal.fire({
        title: "Generando",
        didOpen: () => {
          Swal.showLoading();
        },
      });
      if (data.EstaConfirmado == 1) {
        const horascalculadas = CalculateHours(
          data2.Fecha,
          data2.FechaSalida,
          data2.CostoHoraEmpresa,
          data2.CostoHoraProfesional,
          data2.HoraEntrada,
          data2.HoraSalida
        );
        let horaextracalculada = 0, horastrabajadas = 0;
        if (horascalculadas.canthoras > data2.TotalHoraMaximo) {
          horastrabajadas = horascalculadas.canthoras - data2.TotalHoraMaximo;
          if (horastrabajadas > data.horashoraextra) {
            horaextracalculada = data.horashoraextra
          }
          else {
            horaextracalculada = horastrabajadas
          }
          let horaextra, cantidadhoraextra, IdLegajoEnfermeria, horaextramaximo;
          horaextra = data.horaextra
          cantidadhoraextra = horaextracalculada
          IdLegajoEnfermeria = data.IDLEGAJO
          horaextramaximo = (convertToMinutes(data.horashoraextra) / 60)
          if (horaextra === "False") {
            horaextramaximo = null
          }
          const Guardarhoraextra = await LegajoHoraExtra({ horaextra, cantidadhoraextra, IdLegajoEnfermeria, horaextramaximo })
          if (Guardarhoraextra) {
            Swal.fire({
              title: "Hora Extra Cargado",
              html: `Se cargo correctalmente`,
              icon: "success",
            });
            let data1 = await getlegajosbyliquidacion(id);
      setLiquidacionSinFiltro(data1)
      if (key==="Principal")
        {
          filtroprincipal(data1,filtrogastos)
        }
         else if (key==="Principal2")
        {
          filtrohonorario(data1,filtrogastos)
        }else{
          filtroProf(key,data1,filtrogastos)
        }

          }
        } else {
          Swal.fire({
            title: "Requisitos minimos para Hora Extra ",
            html: `Invalidos`,
            icon: "warning",
          });
        }
      } else {
        let horaextra = data.horaextra
        let cantidadhoraextra = null
        let IdLegajoEnfermeria = data.IDLEGAJO
        let horaextramaximo = (convertToMinutes(data.horashoraextra) / 60)
        if (horaextra === "False") {
          horaextramaximo = null
          cantidadhoraextra = null
        }
        const resultConfirm = await LegajoHoraExtra({ horaextra, cantidadhoraextra, IdLegajoEnfermeria, horaextramaximo });
        if (resultConfirm) {
          Swal.fire({
            title: "Hora Extra Cargado",
            html: `Se cargo correctalmente`,
            icon: "success",
          });
          let data1 = await getlegajosbyliquidacion(id);
          setLiquidacionSinFiltro(data1)
          if (key === "Principal") {
            filtroprincipal(data1,filtrogastos)
          }
          else if (key === "Principal2") {
            filtrohonorario(data1,filtrogastos)
          } else {
            filtroProf(key,data1,filtrogastos)
          }
        }
      }
      Swal.close()
    } catch (err) {
      Swal.close();
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: `${err}`,
      });
    }
  };

  //  front 
  const assigmentValue = useCallback(async () => {
    try {
      Swal.fire({
        title: "Cargando Datos",
        allowOutsideClick: false,
        showCancelButton: false,
        showConfirmButton: false,
      });
      const data = await getliquidacionbyid(id); // datos del cierre
      data[0].estado == "LIQUIDADO" ? setisliqui(true) : setisliqui(false);
      getliquidaciondata(data);
      const _ = require("lodash");

      // marcaciones
      const data1 = await getlegajosbyliquidacion(id);
      const Marcaciones = data1.filter((num) => (num.paraTarifa === "SI")); //inicializa con Marcaciones Principal (tarifa)
      const SumaMarcaciones = _.sumBy(Marcaciones, (item2) =>
        Number(
          item2.CostoHoraEmpresa * (Number(Number(item2.horashoraextra).toFixed(2))+ item2.horascalculadas) + ((item2.CostoHoraEmpresa * item2.horasferiado) * item2.porcentajeferiado / 100)
        )
      ); // se obtiene la suma del monto
      const SumaHoraMarcaciones = _.sumBy(Marcaciones, (item2) =>
        Number((item2.horascalculadas + item2.horashoraextra).toFixed(2))
      ); //se Obtiene la suma del las horas 
      setliquidacion(Marcaciones) // lista de marcaciones filtro
      setLiquidacionSinFiltro(data1) // todas las marcaciones
      setSumaHoras(Number(SumaHoraMarcaciones)) //total de horas
      setSumaMonto(Math.round(SumaMarcaciones)) //total monto
      //fin marcaciones 

      //nombre del paciente
      setPaciente(data1[0].Paciente)


      // otros
      const listagastos = await getgastosbyliquidacion(id)
      let gastosfiltrado = listagastos.filter((num) => (num.paraTarifa === "SI")); //inicializa con Gastos Principal
      const sumgastos = _.sumBy(gastosfiltrado, (item2) =>
        Number(item2.monto)
      );
      setMontoGasto(Math.round(sumgastos))
      setgastos(gastosfiltrado)
      setfiltrogastos(listagastos)
      //fin otros      

      // enfermeros
      const result = data1.map((number) => {
        const container = {};

        container.id = number.IdUsuario;
        container.name = number.Usuario;

        return container;
      });

      const listprof = new Map(result.map((c) => [c.id, c])); // los profesionales
      const uniquepprof = [...listprof.values()];

      // sumas liquidacion tarifa y liquidacion honorario


      setlista(uniquepprof);

      Swal.close();
    } catch (err) {
      Swal.close();
      console.log(err);
    }
  }, []);
  useEffect(() => {
    assigmentValue();
  }, [assigmentValue]);

  const Tabclick = (k) => {
    setKey(k);

    if (k === "Principal") {
      filtroprincipal(LiquidacionSinFiltro,filtrogastos)
    }
    else if (k === "Principal2") {
      filtrohonorario(LiquidacionSinFiltro,filtrogastos)
    } else {
      filtroProf(k,LiquidacionSinFiltro,filtrogastos)
    }
  };
  const filtroprincipal = (dat,datgastos) => {


    const _ = require("lodash");
    let filmarcaciones = dat.filter((num) => (num.paraTarifa === "SI")); //inicializa con Marcaciones Principal (tarifa)
    let Suma = _.sumBy(filmarcaciones, (item2) =>
      Number(
        item2.CostoHoraEmpresa * (Number(Number(item2.horashoraextra).toFixed(2)) + item2.horascalculadas) + ((item2.CostoHoraEmpresa * item2.horasferiado) * item2.porcentajeferiado / 100)
      )
    ); // se obtiene la suma del monto
    let SumaHora = _.sumBy(filmarcaciones, (item2) =>
      Number((item2.horascalculadas + item2.horashoraextra).toFixed(2))
    ); //se Obtiene la suma del las horas 
    setSumaMonto(Math.round(Suma))
    setSumaHoras(SumaHora)
    setliquidacion(filmarcaciones)


    let gastosfiltrado = datgastos.filter((num) => (num.paraTarifa === "SI")); //inicializa con Gastos Principal
    let sumgastos = _.sumBy(gastosfiltrado, (item2) =>
      Number(item2.monto)
    );
    setgastos(gastosfiltrado)
    setMontoGasto(Math.round(sumgastos))


  };
  const filtrohonorario = (dat,datgastos) => {


    const _ = require("lodash");
    let filmarcaciones = dat.filter((num) => (num.paraHonorario === "SI"));
    let Suma = _.sumBy(filmarcaciones, (item2) =>
      Number(
        item2.CostoHoraProfesional * (Number(Number(item2.horashoraextra).toFixed(2)) + item2.horascalculadas) + ((item2.CostoHoraProfesional * item2.horasferiado) * item2.porcentajeferiado / 100)
      )
    ); // se obtiene la suma del monto
    let SumaHora = _.sumBy(filmarcaciones, (item2) =>
      Number((item2.horascalculadas + item2.horashoraextra).toFixed(2))
    ); //se Obtiene la suma del las horas 
    setSumaMonto(Math.round(Suma))
    setSumaHoras(SumaHora)
    setliquidacion(filmarcaciones)


    let gastosfiltrado = datgastos.filter((num) => (num.paraHonorario === "SI"));
    let sumgastos = _.sumBy(gastosfiltrado, (item2) =>
      Number(item2.monto)
    );
    setMontoGasto(Math.round(sumgastos))
    setgastos(gastosfiltrado)

  };
  const filtroProf = (k,dat,datgastos) => {

    const _ = require("lodash");

    let filmarcaciones = dat.filter((num) => (num.paraHonorario === "SI" && num.IdUsuario == k)); //inicializa con Marcaciones Principal (tarifa)
    let Suma = _.sumBy(filmarcaciones, (item2) =>
      Number(
        item2.CostoHoraProfesional * (Number(Number(item2.horashoraextra).toFixed(2)) + item2.horascalculadas) + ((item2.CostoHoraProfesional * item2.horasferiado) * item2.porcentajeferiado / 100)
      )
    ); // se obtiene la suma del monto
    let SumaHora = _.sumBy(filmarcaciones, (item2) =>
      Number((item2.horascalculadas + item2.horashoraextra).toFixed(2))
    ); //se Obtiene la suma del las horas 
    setSumaMonto(Math.round(Suma))
    setSumaHoras(SumaHora)
    setliquidacion(filmarcaciones)

    let gastosfiltrado = datgastos.filter((num) => (num.paraHonorario === "SI" && num.idUsuario == k)); //inicializa con Gastos Principal
    let sumgastos = _.sumBy(gastosfiltrado, (item2) =>
      Number(item2.monto)
    );
    setMontoGasto(Math.round(sumgastos))
    setgastos(gastosfiltrado)

  };
  const [key, setKey] = useState("Principal");

  // font

  // descargas pdfs
  const descargarpdf = () => {
    const _ = require("lodash");
    let dato = LiquidacionSinFiltro.filter((num) => (num.paraTarifa === "SI"));
    let datgastos = filtrogastos.filter((num) => (num.paraTarifa === "SI"));
    let Sumgastos = _.sumBy(datgastos, (item2) =>
      Number(item2.monto)
    );
    let Suma = _.sumBy(dato, (item2) =>
      Number(
        item2.CostoHoraEmpresa * (Number(Number(item2.horashoraextra).toFixed(2)) + item2.horascalculadas) + ((item2.CostoHoraEmpresa * item2.horasferiado) * item2.porcentajeferiado / 100)
      )
    );


    let tipo = "Tarifa"
    let inicio = liquidaciondata[0].fechainicio
    let fin = liquidaciondata[0].fechafin
    genPDF({ dato, tipo, inicio, fin, datgastos, Sumgastos, Suma })
  }
  const descargarpdfhonorarios = () => {
    const _ = require("lodash");
    let dato = LiquidacionSinFiltro.filter((num) => (num.paraHonorario === "SI"));
    let datgastos = filtrogastos.filter((num) => (num.paraHonorario === "SI"));
    let Sumgastos = _.sumBy(datgastos, (item2) =>
      Number(item2.monto)
    );
    let Suma = _.sumBy(dato, (item2) =>
      Number(
        item2.CostoHoraProfesional * (Number(Number(item2.horashoraextra).toFixed(2)) + item2.horascalculadas) + ((item2.CostoHoraProfesional * item2.horasferiado) * item2.porcentajeferiado / 100)
      )
    );


    let tipo = "Honorario"
    let inicio = liquidaciondata[0].fechainicio
    let fin = liquidaciondata[0].fechafin
    genPDF({ dato, tipo, inicio, fin, datgastos, Sumgastos, Suma })
  }
  const descargarpdfprof = async () => {
    const Usuario = await getUsuarioById(key)
    const _ = require("lodash");
    let NombreUsuario = Usuario[0].Nombre
    let Liquidacion = LiquidacionSinFiltro.filter((num) => (num.paraHonorario === "SI"  && num.IdUsuario == key ));
    let datgastos = filtrogastos.filter((num) => (num.paraHonorario === "SI" && num.idUsuario == key));
    let Sumgastos = _.sumBy(datgastos, (item2) =>
      Number(item2.monto)
    );
    let Suma = _.sumBy(Liquidacion, (item2) =>
      Number(
        item2.CostoHoraProfesional * (Number(Number(item2.horashoraextra).toFixed(2)) + item2.horascalculadas) + ((item2.CostoHoraProfesional * item2.horasferiado) * item2.porcentajeferiado / 100)
      )
    );


    let inicio = liquidaciondata[0].fechainicio
    let fin = liquidaciondata[0].fechafin
    genPDF2({ Liquidacion, NombreUsuario, inicio, fin, datgastos, Sumgastos, Suma })
  }
  const descargarrecivo = async () => {
    Swal.fire({
      title: "Generando",
      didOpen: () => {
        Swal.showLoading();
      },
    });
    // filtrar y sumar 


    const datos = Liquidacion
    let iduser = datos[0].IdUsuario
    let len = datos.length
    let fechafin = datos[len - 1].FechaSalida
    let fechain = datos[0].Fecha
    const Paciente = await getPacientesById(datos[0].IdPaciente)
    const Usuario = await getUsuarioById(datos[0].IdUsuario)
    let idrecibo = await getidrecibo(datos[0].IdUsuario, id)
    const nombrepaciente = Paciente[0].NombrePaciente
    const nombreusuario = Usuario[0].Nombre
    if (idrecibo.length > 0) {
      let idrec = idrecibo[0].idrecibo
      pdfrecibo({ nombrepaciente, nombreusuario, SumaMonto, idrec, fechain, fechafin })
    }
    else {
      const idrecibo2 = await InsertRecibo({ nombrepaciente, nombreusuario, SumaMonto, iduser, id })
      idrecibo = await getidrecibo(datos[0].IdUsuario, id)
      let idrec = idrecibo[0].idrecibo
      pdfrecibo({ nombrepaciente, nombreusuario, SumaMonto, idrec, fechain, fechafin })
    }
    Swal.close();
  }


  // descargas
  const convertToMinutes = function (timeString) {
    var hms = timeString.split(':');
    return Math.ceil(parseInt(hms[1]) + parseInt(hms[0]) * 60)
  }
  const CalculateHours = (
    fechaBegin,
    fechaEnd,
    tarifa,
    honorario,
    HoraEntrada,
    HoraSalida
  ) => {
    var fecha1 = moment(fechaBegin + " " + HoraEntrada, "YYYY-MM-DD HH:mm:ss");
    var fecha2 = moment(fechaEnd + " " + HoraSalida, "YYYY-MM-DD HH:mm:ss");

    var diff = fecha2.diff(fecha1, "m"); // Diff in hours
    return {
      canthoras: Math.round(diff / 60),
      totalHonorario: Math.round((honorario * diff) / 60),
      totalTarifa: Math.round((tarifa * diff) / 60),
    };
  };
 
  //gasto
  const NewGasto = async (data) => {
    dispatchReducer({ type: "END" });
    try {
      let idliquidacion = id;
      let idPaciente = Liquidacion[0].IdPaciente
      let paciente = nombrepaciente
      await AddGasto({ ...data, idliquidacion, idPaciente, paciente });
      let listagastos = await getgastosbyliquidacion(id)
      setfiltrogastos(listagastos)
      if (key === "Principal") {
        filtroprincipal(LiquidacionSinFiltro,listagastos)
      }
      else if (key === "Principal2") {
        filtrohonorario(LiquidacionSinFiltro,listagastos)
      } else {
        filtroProf(key,LiquidacionSinFiltro,listagastos)
      }

    } catch (err) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: `${err}`,
      });
    }
    //liquidar
  };
  const handleeditgastos = async (data) => {
    seteditdata(data)
    dispatchReducer({ type: "SHOWEDITGASTOS" });
  };
  const EditarGasto = async (data) => {
    dispatchReducer({ type: "END" });
    try {
      await EditGasto(data);
      let listagastos = await getgastosbyliquidacion(id)
      setfiltrogastos(listagastos)
      if (key === "Principal") {
        filtroprincipal(LiquidacionSinFiltro,listagastos)
      }
      else if (key === "Principal2") {
        filtrohonorario(LiquidacionSinFiltro,listagastos)
      } else {
        filtroProf(key,LiquidacionSinFiltro,listagastos)
      }
      
    } catch (err) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: `${err}`,
      });
    }

  };
  return (
    <>
      {liquidaciondata.map((item, index) => {
        return (
          <div key={index}>
            <div className="col-xl-12">
              <div className="page-titles acti-space">
                <h2 className="heading sm-mb-0 mb-2">{Paciente}</h2>
                <div className="d-flex align-items-center flex-wrap">
                  <div className="me-2">
                    <ButtonGroup aria-label="Basic example">
                      <Button
                        variant="primary"
                        disabled={isliqui}
                        onClick={handleShow}
                      >
                       Actualizar Estado
                      </Button>

                    </ButtonGroup>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-12 col-xxl-12">
              <div className="row">
                <div className="col-xl-12">
                  <div className="card">
                    <div className="card-header">
                      <h4 className="card-title">Datos de Cierre</h4>
                    </div>
                    <div className="card-body p-4">
                      <span>Generado el: {item.FechaCreacion} </span>
                      <br></br>
                      <span> Generado por : {item.UsuarioCreacion} </span>
                      <br></br>
                      <span>Liquidado/Enviado por : {item.UsuarioEdicion} </span>
                      <br></br>
                      <span>Factura numero : {item.facturanumero} </span>
                      <br></br>
                      <span>Estado : {item.estado}</span>
                      <br></br>
                      <span>Fecha inicio: {item.fechainicio}</span>
                      <br></br>
                      <span>Fecha fin : {item.fechafin} </span>
                      <br></br>
                      <span>Obs. : <strong>{item.obs}</strong> </span>
                      <br></br>
                      <span>Obs. para Liquidacion : <strong>{item.obs2}</strong> </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <div className="col-xl-12 col-xxl-12">
                  <div className="row">
                    <div className="col-xl-12">
                      <div className="card">
                        <div className="card-header">
                          <h4 className="card-title">Extras</h4>
                          <OverlayTrigger
                            placement="bottom"
                            overlay={<Tooltip id="button-tooltip-2">Gastos</Tooltip>}>
                            <Button onClick={handleSAddGastos}><i

                              style={{ fontSize: "25px" }}
                            >

                              <TbUserDollar />
                            </i></Button></OverlayTrigger>
                        </div>
                        <div className="card-body p-4">
                          <Table
                            responsive
                            className="primary-table-bordered col-6 "
                            id="my-table"

                          >
                            <thead className="thead-primary">
                              <tr>
                                <th scope="col">Detalle</th>
                                <th scope="col">Monto</th>
                                <th scope="col">Opciones</th>
                              </tr>
                            </thead>
                            <tbody>
                              {gastos.map((item2, index3) => {

                                return (
                                  <tr key={index3}>
                                    <td>{item2.gasto}</td>
                                    <td>{item2.monto.toLocaleString('es')}</td>
                                    <td>
                                      <Button onClick={() => handleeditgastos(item2)} className="p-1">
                                        <i
                                          className="material-icons"
                                          style={{ height: "100" }}
                                        >
                                          <CiEdit />
                                        </i>
                                      </Button>
                                    </td>

                                  </tr>
                                );
                              })}
                            </tbody>
                            <tfoot>
                              <tr>
                                <td colSpan={1}>Total</td>
                                <td>{MontoGasto.toLocaleString('es')}</td>
                              </tr>
                            </tfoot>
                          </Table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="col-xl-12 col-xxl-12">
                  <div className="row">
                    <div className="col-xl-12">
                      <div className="card">
                        <div className="card-header">
                          <h4 className="card-title">Total</h4>
                        </div>
                        <div className="card-body p-4">
                          <Table
                            responsive
                            className="primary-table-bordered col-6 "
                            id="my-table"

                          >
                            <thead className="thead-primary">
                              <tr>

                                <th scope="col">Detalle</th>
                                <th scope="col">Monto</th>
                              </tr>
                            </thead>
                            <tbody>

                              <tr>

                                <td>Enfermeria</td>
                                <td>{SumaMonto.toLocaleString('es')}</td>
                              </tr>
                              <tr>

                                <td>Otros</td>
                                <td>{MontoGasto.toLocaleString('es')}</td>
                              </tr>
                            </tbody>
                            <tfoot>
                              <tr>
                                <td colSpan={1}>Total</td>
                                <td>{(SumaMonto + MontoGasto).toLocaleString('es')}</td>
                              </tr>
                            </tfoot>
                          </Table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-12 col-xl-12">
              <div className="row">
                <div className="col-xl-12">
                  <div className="card">
                    <div className="card-header">
                      <h4 className="card-title">Marcaciones</h4>
                      <OverlayTrigger
                        placement="bottom"
                        overlay={<Tooltip id="button-tooltip-2"> Agregar Marcacion</Tooltip>}>
                        <Button onClick={handleShow3}><i

                          style={{ fontSize: "25px" }}
                        >
                          <LiaUserClockSolid />
                        </i></Button>
                      </OverlayTrigger>

                    </div>
                    <div className="card-body p-4">
                      <Tabs
                        id="controlled-tab-example"
                        activeKey={key}
                        onSelect={(k) => Tabclick(k)}
                        className="mb-3"
                      >
                        <Tab eventKey="Principal" title="Principal">
                          <Table
                            responsive
                            className="primary-table-bordered col-6 "
                            id="my-table"

                          >
                            <thead className="thead-primary">
                              <tr>
                                <th scope="col">#</th>
                                <th scope="col">Fecha Entrada</th>
                                <th scope="col">Hora Entrada</th>
                                <th scope="col">Hora Salida</th>
                                <th scope="col">Horas</th>
                                <th scope="col">Tarifa</th>
                                <th scope="col">Enfermero</th>
                                <th scope="col">Opciones</th>
                              </tr>
                            </thead>
                            <tbody>
                              {Liquidacion.map((item2, index3) => {
                                let colortext
                                let colortextferiado
                                let disa = true
                                if (item2.isPreview === "SI") {
                                  colortext = "black"
                                }
                                if (item2.isPreview === "NO") {
                                  colortext = "grey"
                                }
                                if (item2.esFeriado === "SI") {
                                  colortextferiado = "orange"
                                  disa = false
                                }
                                if (item2.esFeriado === "NO") {
                                  colortextferiado = colortext
                                  disa = false
                                }
                                if (item2.HoraExtra === "True") {
                                 colortext = "blue"
                                }
                                return (
                                  <tr key={index3} style={{ color: `${colortext}` }}>
                                    <td >{index3}</td>
                                    <td style={{ color: `${colortextferiado}` }}>{item2.Fecha}</td>
                                    <td>{formatTime(item2.HoraEntrada)}</td>
                                    <td>
                                      {horasalida(
                                        item2.Fecha,
                                        item2.HoraEntrada,
                                        item2.horascalculadas +
                                        item2.horashoraextra
                                      )}
                                    </td>
                                    <td>
                                      {(item2.horascalculadas +
                                        item2.horashoraextra).toFixed(2)}
                                    </td>
                                    <td>
                                      {Math.round(item2.CostoHoraEmpresa *
                                        (Number(Number(item2.horashoraextra).toFixed(2)) + item2.horascalculadas) + ((item2.CostoHoraEmpresa * item2.horasferiado) * item2.porcentajeferiado / 100)).toLocaleString('es')}
                                    </td>
                                    <td>{item2.Usuario}</td>
                                    <td>
                                      <OverlayTrigger
                                        placement="bottom"
                                        overlay={<Tooltip id="button-tooltip-2">{item2.Observacion}</Tooltip>}><Button onClick={() => handleShow2(item2)} className="p-1">
                                          <i
                                            className="material-icons"
                                            style={{ height: "100" }}
                                          >
                                            <CiEdit />
                                          </i>
                                        </Button></OverlayTrigger>
                                      <Button onClick={() => handleShowFeriado(item2)} className="p-1">
                                        <i
                                          className="material-icons"
                                          style={{ height: "100" }}
                                        >
                                          <CiCalendarDate />
                                        </i>
                                      </Button>
                                      <OverlayTrigger
                                        placement="bottom"
                                        overlay={<Tooltip id="button-tooltip-2">{Number(item2.horashoraextra).toFixed(2)}</Tooltip>}>
                                        <Button className="p-1">
                                          <i
                                            onClick={() => showModalHoraExtra(item2)}
                                            className="material-icons "
                                            style={{ height: "100" }}
                                          >
                                            <IoMdTimer />
                                          </i>
                                        </Button></OverlayTrigger>
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                            <tfoot>
                              <tr>
                                <td colSpan={4}>Total</td>
                                <td>{SumaHoras.toFixed(2)}</td>
                                <td>{SumaMonto.toLocaleString('es')}</td>
                              </tr>
                            </tfoot>
                          </Table>
                          <Button onClick={descargarpdf}>Descargar PDF</Button>


                        </Tab>
                        {/*Prof totales*/}
                        <Tab eventKey="Principal2" title="Honorarios">
                          <Table
                            responsive
                            className="primary-table-bordered col-6 "
                            id="my-table"

                          >
                            <thead className="thead-primary">
                              <tr>
                                <th scope="col">#</th>
                                <th scope="col">Fecha Entrada</th>
                                <th scope="col">Hora Entrada</th>
                                <th scope="col">Hora Salida</th>
                                <th scope="col">Horas</th>
                                <th scope="col">Honorario</th>
                                <th scope="col">Enfermero</th>
                                <th scope="col">Opciones</th>
                              </tr>
                            </thead>
                            <tbody>
                              {Liquidacion.map((item2, index4) => {
                                   let colortext
                                   let colortextferiado
                                   let disa = true
                                   if (item2.isPreview === "SI") {
                                     colortext = "black"
                                   }
                                   if (item2.isPreview === "NO") {
                                     colortext = "grey"
                                   }
                                   if (item2.esFeriado === "SI") {
                                     colortextferiado = "orange"
                                     disa = false
                                   }
                                   if (item2.HoraExtra === "True") {
                                    colortext = "blue"
                                   }
                                return (

                                  <tr key={index4} style={{ color: `${colortext}` }}>
                                    <td>{index4}</td>
                                    <td style={{ color: `${colortextferiado}` }} >{item2.Fecha}</td>
                                    <td>{formatTime(item2.HoraEntrada)}</td>
                                    <td>
                                      {horasalida(
                                        item2.Fecha,
                                        item2.HoraEntrada,
                                        item2.horascalculadas +
                                        item2.horashoraextra
                                      )}
                                    </td>
                                    <td>
                                      {(item2.horascalculadas +
                                        item2.horashoraextra).toFixed(2)}
                                    </td>
                                    <td>
                                      {Math.round(item2.CostoHoraProfesional *
                                        (Number(Number(item2.horashoraextra).toFixed(2)) + item2.horascalculadas) + ((item2.CostoHoraProfesional * item2.horasferiado) * item2.porcentajeferiado / 100)).toLocaleString('es')}
                                    </td>
                                    <td>{item2.Usuario}</td>
                                    <td><OverlayTrigger
                                      placement="bottom"
                                      overlay={<Tooltip id="button-tooltip-2">{item2.Observacion}</Tooltip>}><Button onClick={() => handleShow2(item2)} className="p-1">
                                        <i
                                          className="material-icons"
                                          style={{ height: "100" }}
                                        >
                                          <CiEdit />
                                        </i>
                                      </Button></OverlayTrigger>
                                      <Button onClick={() => handleShowFeriado(item2)} className="p-1">
                                        <i
                                          className="material-icons "
                                          style={{ height: "100" }}
                                        >
                                          <CiCalendarDate />
                                        </i>
                                      </Button>
                                      <OverlayTrigger
                                        placement="bottom"
                                        overlay={<Tooltip id="button-tooltip-2">{Number(item2.horashoraextra).toFixed(2)}</Tooltip>}>
                                        <Button className="p-1">
                                          <i
                                            onClick={() => showModalHoraExtra(item2)}
                                            className="material-icons "
                                            style={{ height: "100" }}
                                          >
                                            <IoMdTimer />
                                          </i>
                                        </Button></OverlayTrigger></td>
                                  </tr>
                                );
                              })}
                            </tbody>
                            <tfoot>
                              <tr>
                                <td colSpan={4}>Total</td>
                                <td>{SumaHoras.toFixed(2)}</td>
                                <td>{SumaMonto.toLocaleString('es')}</td>
                              </tr>
                            </tfoot>
                          </Table>
                          <Button onClick={descargarpdfhonorarios}>Descargar PDF</Button>

                        </Tab>
                        {listaprofesionales.map((item2, index6) => proftabs(item2, index6))}
                      </Tabs>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      })}
      {reducer.isShow && (
        <ModalLiquidar
          estado={reducer.isShow}
          onCloseModal={onCloseModal}
          onReturnValue={Liquidar}
        />
      )}
      {reducer.isShow2 && (
        <ModalEditarLegajo
          estado={reducer.isShow2}
          onCloseModal={onCloseModal}
          onReturnValue={EditLegajoFunc}
          confirmed={confirmed}
          rowdata={rowdata}
          prev={prev}
        />
      )}
      {reducer.isShow3 && (
        <ModalLegajo
          estado={reducer.isShow3}
          onCloseModal={onCloseModal}
          onReturnValue={NewLegajo}
          isNew={true}
        />
      )}
      {reducer.isShow4 && (
        <ModalFeriado
          estado={reducer.isShow4}
          onCloseModal={onCloseModal}
          onReturnValue={EditFeriadoFunc}
          rowdata={rowdata}
        />
      )}
      {reducer.isShow5 && (
        <ModalHoraExtra
          estado={reducer.isShow5}
          onReturnValue={FuncionHoraExtra}
          onCloseModal={onCloseModal}
          item={rowdata}
        />
      )}
      {reducer.isShowGASTOS && (
        <ModalGastos
          estado={reducer.isShowGASTOS}
          onCloseModal={onCloseModal}
          onReturnValue={NewGasto}
        />
      )}
      {reducer.isShowEditGASTOS && (
        <ModalEditGastos
          estado={reducer.isShowEditGASTOS}
          onCloseModal={onCloseModal}
          rowdata={editdata}
          onReturnValue={EditarGasto}
        />
      )}
    </>
  );
};
export default FichaLiquidacion;
