import { ColumnFilter } from "./ColumnFilter";

export const CLIENTE_COLUMNS = [
  {
    Header: "ID",
    Footer: "ID",
    accessor: "IdPacientes",
    Filter: ColumnFilter,
    Cell: (props) => {
			return (
			  <p   style={{
				textAlign:"Center", 
			  }}>
				{props.value}
			  </p>
			);
		  },
      
  },
  {
    Header: "Nombre",
    Footer: "Nombre",
    accessor: "NombrePaciente",
    Filter: ColumnFilter,
    Cell: (props) => {
			return (
			  <p   style={{
				textAlign:"Center"
			  }}>
				{props.value}
			  </p>
			);
		  },
  },
  {
    Header: "Cedula",
    Footer: "Cedula",
    accessor: "Cedula",
    Filter: ColumnFilter,
    Cell: (props) => {
			return (
			  <p   style={{
				textAlign:"Center"
			  }}>
				{props.value}
			  </p>
			);
		  },
  },
  {
    Header: "Ciudad",
    Footer: "Ciudad",
    accessor: "Ciudad",
    Filter: ColumnFilter,
    Cell: (props) => {
      
			return (
			  <p   style={{
				textAlign:"Center",
			  }}>
				{props.value}
			  </p>
			);
		  },
  },
  
  {
    Header: "Celular",
    Footer: "Celular",
    accessor: "Celular",
    Filter: ColumnFilter,
    Cell: (props) => {
			return (
			  <p   style={{
				textAlign:"Center"
			  }}>
				{props.value}
			  </p>
			);
		  },
  },
  {
    Header: "Nivel",
    accessor: "nivel",
    Filter: ColumnFilter,
    Cell: (props) => {
      let color
      if (props.value==="Nivel 1")
      {
        color ="green"
      }else if ((props.value==="Nivel 2"))
      {
        color="orange"
      }else 
      { 
        color ="red"
      }
			return (
			  <p   style={{
				textAlign:"Center",
        color:`${color}`
			  }}>
				{props.value}
			  </p>
			);
		  },
  },
  {
    Header: "Estado",
    accessor: "estaActivo",
    Filter: ColumnFilter,
    Cell: (props) => {
      let colores="black"
      if (props.value=="Activo")
      {
        colores="green"
      }if (props.value=="Inactivo")
      {
        colores="red"
      }
			return (
			  <p   style={{
        color:`${colores}`,
        textAlign:"Center"
			  }}>
				{props.value}
			  </p>
			);
		  },
  },
  
];

export const COLUMNS = [
  {
    Header: "ID",
    accessor: "IdUsuario",
    Filter: ColumnFilter,
    //disableFilters: true,
    Cell: (props) => {
			return (
			  <p   style={{
				textAlign:"Center"
			  }}>
				{props.value}
			  </p>
			);
		  },
  },
  {
    Header: "nombre",
    accessor: "Nombre",
    Filter: ColumnFilter,
    Cell: (props) => {
			return (
			  <p   style={{
				textAlign:"Center"
			  }}>
				{props.value}
			  </p>
			);
		  },
  },
  {
    Header: "CI",
    Footer: "CI",
    accessor: "Cedula",
    Filter: ColumnFilter,
    Cell: (props) => {
			return (
			  <p   style={{
				textAlign:"Center"
			  }}>
				{props.value}
			  </p>
			);
		  },
  },
  {
    Header: "Cargo",
   
    accessor: "IdTipoUsuario",

    Filter: ColumnFilter,
    Cell: (props) => {
			return (
			  <p   style={{
				textAlign:"Center"
			  }}>
				{props.value}
			  </p>
			);
		  },
  },
  {
    Header: "Ciudad",
    Footer: "Ciudad",
    accessor: "Ciudad",
    Filter: ColumnFilter,
    Cell: (props) => {
			return (
			  <p   style={{
				textAlign:"Center"
			  }}>
				{props.value}
			  </p>
			);
		  },
  },
  {
    Header: "Telefono",
    Footer: "Telefono",
    accessor: "Telefono",
    Filter: ColumnFilter,
    Cell: (props) => {
			return (
			  <p   style={{
				textAlign:"Center"
			  }}>
				{props.value}
			  </p>
			);
		  },
  },
  {
    Header: "Estado",
    accessor: "estaActivo",
    Filter: ColumnFilter,
    Cell: (props) => {
      let colores="black"
      if (props.value=="Activo")
      {
        colores="green"
      }if (props.value=="Inactivo")
      {
        colores="red"
      }
			return (
			  <p   style={{
        color:`${colores}`,
        textAlign:"Center"
			  }}>
				{props.value}
			  </p>
			);
		  },
  },
];
export const COLUMNS_LIQ = [
  {
    Header: "ID",
    accessor: "id",
    Filter: ColumnFilter,
    //disableFilters: true,
    Cell: (props) => {
			return (
			  <p   style={{
				textAlign:"Center"
			  }}>
				{props.value}
			  </p>
			);
		  },
  },
  {
    Header: "Usuario",
    Footer: "CI",
    accessor: "Nombre",
    Filter: ColumnFilter,
    Cell: (props) => {
			return (
			  <p   style={{
				textAlign:"Center"
			  }}>
				{props.value}
			  </p>
			);
		  },
  },
  {
    Header: "Fecha de Inicio",
    accessor: "FechaInicio",
    Filter: ColumnFilter,
    Cell: (props) => {
			return (
			  <p   style={{
				textAlign:"Center"
			  }}>
				{props.value}
			  </p>
			);
		  },
  },
  {
    Header: "Fecha de Cierre",
   
    accessor: "FechaFin",

    Filter: ColumnFilter,
    Cell: (props) => {
			return (
			  <p   style={{
				textAlign:"Center"
			  }}>
				{props.value}
			  </p>
			);
		  },
  },
  {
    Header: "Estado",
    Footer: "Ciudad",
    accessor: "Estado",
    Filter: ColumnFilter,
    Cell: (props) => {
      let colores="black"
      if (props.value=="CIERRE")
      {
        colores="RED"
      }
      if (props.value=="LIQUIDADO")
      {
        colores="GREEN"
      }
      if (props.value=="Liquidado")
        {
          colores="GREEN"
        }
      if (props.value=="ENVIADO")
        {
          colores="ORANGE"
        }
			return (
			  <p   style={{
        color:`${colores}`,
        textAlign:"Center"
			  }}>
				{props.value}
			  </p>
			);
		  },
  },
 
];
export const GROUPED_COLUMNS = [
  {
    Header: "Id",
    Footer: "Id",
    accessor: "id",
  },
  {
    Header: "Nombre",
    Footer: "Nombre",
    columns: [
      {
        Header: "Nombre",
        Footer: "Nombre",
        accessor: "Nombre",
      },
      {
        Header: "Apellido",
        Footer: "Apellido",
        accessor: "Apellido",
      },
    ],
  },
  {
    Header: "Info",
    Footer: "Info",
    columns: [
      {
        Header: "CI",
        Footer: "CI",
        accessor: "CI",
      },
      {
        Header: "Ciudad",
        Footer: "Ciudad",
        accessor: "Ciudad",
      },
      {
        Header: "Telefono",
        Footer: "Telefono",
        accessor: "Telefono",
      },
    ],
  },
];
export const GENERAR_LIQUIDACION_COLUMNS = [
  {
    Header: "Fecha Entrada",
    Footer: "Fecha Entrada",
    accessor: "Fecha",
    isEditable: true,
    meta: {
      type: "date",
    },
  },
  {
    Header: "Hora Entrada",
    Footer: "Hora Entrada",
    accessor: "HoraEntrada",
    isEditable: true,
    meta: {
      type: "time",
    },
  },
  {
    Header: "Fecha Salida",
    Footer: "Fecha Salida",
    accessor: "FechaSalida",
    isEditable: true,
    meta: {
      type: "date",
    },
  },
  {
    Header: "Hora Salida",
    Footer: "Hora Salida",
    accessor: "HoraSalida",
    isEditable: true,
    meta: {
      type: "time",
    },
  },
  {
    Header: "Horas",
    Footer: "Horas",
    accessor: "horascalculadas",
    isEditable: false,
    meta: {
      type: "text",
    },
  },
  {
    Header: "Horas Extra",
    Footer: "Horas Extra",
    accessor: "horashoraextra",
    isEditable: false,
    meta: {
      type: "text",
    },
  },
  {
    Header: "Tarifa",
    Footer: "Tarifa",
    accessor: row => Math.round((row.horascalculadas+row.horashoraextra)*(row.CostoHoraEmpresa)),
    isEditable: false,
    meta: {
      type: "text",
    },
  },
  {
    Header: "Honorario",
    Footer: "Honorario",
    accessor: row => Math.round((row.horascalculadas+row.horashoraextra)*(row.CostoHoraProfesional)),
    isEditable: false,
    meta: {
      type: "text",
    },
  },
  {
    Header: "Enfermero",
    Footer: "Enfermero",
    accessor: "Usuario",
    isEditable: true,
    meta: {
      type: "selectbox",
    },
  },
];
