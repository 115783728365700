export const REACT_APP_LINKMAP = `https://api.ipgeolocation.io/timezone?apiKey=193c9f6a681840699c8804933ae35a45&lat`;

export const TYPE_USER = Object.freeze({
  ADMINISTRACION: {
    Id: 1,
    Nombre: "Administracion",
  },
  LICENCIADO: {
    Id: 2,
    Nombre: "Licenciado",
  },
  LOGISTICA: {
    Id: 3,
    Nombre: "Logistica",
  },
  TECNICO: {
    Id: 4,
    Nombre: "Tecnico",
  },
  CUIDADOR: {
    Id: 5,
    Nombre: "Cuidador",
  },
  OPERATIVA: {
    Id: 6,
    Nombre: "Operativa",
  },
});
