
import { jsPDF } from "jspdf";
import autoTable from 'jspdf-autotable'
import Headerimg from '../../../utils/header.jpeg'
import Footerimg from '../../../utils/footer.jpeg'
function Bodyrows(Sumatotal, sumgastos) {
  var body = []
  body.push({
    Detalle: "Enfermeria",
    Monto: Sumatotal.toLocaleString('es')
  })
  body.push({
    Detalle: "Otros Servicios",
    Monto: sumgastos.toLocaleString('es')
  })
  body.push({
    Detalle: "TOTAL",
    Monto: (sumgastos + Sumatotal).toLocaleString('es')
  })
  return body
}

function horasalida(fecha, hora, horas) {
  let fechatotal = new Date(`${fecha} ${hora}`);
  const hoursToMs = fechatotal.getTime() + horas * 60 * 60 * 1000;

  fechatotal.setTime(hoursToMs);
  const showTime =
    addZeroBefore(fechatotal.getHours()) +
    ":" +
    addZeroBefore(fechatotal.getMinutes()) +
    ":" +
    addZeroBefore(fechatotal.getSeconds());

  return `${showTime}`;
}
function addZeroBefore(n) {
  return (n < 10 ? "0" : "") + n;
}
const formatTime = (value) => {
  return value.split(".")[0];
};


export default function genPDF2(props) 
  {
    var rows = [];
    var colotros = ["Detalle", "Monto"]
  var rowotros = []
    var col = ["Fecha","Hora Entrada","Fecha Salida","Hora Salida","Horas","Honorario","Enfermero"];
    const {Liquidacion,NombreUsuario,inicio,fin,datgastos,Sumgastos, Suma} = props; 
    let sumgastos = Sumgastos
    let gastos= datgastos
    let Sumatotal = Math.round(Suma)
    let datos = Liquidacion
    var doc = new jsPDF();
    var xOffset = doc.internal.pageSize.width / 2
    var totalPagesExp = "{total_pages_count_string}";
    doc.setFontSize(20);
    doc.text(`${NombreUsuario}`, xOffset, 50, null, null, "center");
    doc.setFontSize(15);
    doc.setTextColor(112, 112, 112);
    doc.setFontSize(10);
    doc.text("Liquidación", xOffset, 56, null, null, "center");
    doc.setFontSize(15);
    doc.text(`${datos[0].Paciente}`, xOffset, 63, null, null, "center");
    let totalhoras=0
    let totalhonorario=0
       datos.forEach((element, key, arr) => {    
         let HoraSalida=   horasalida(
            element.Fecha,
            element.HoraEntrada,
            element.horascalculadas +
            element.horashoraextra
          )
            let HorasCalculadas = element.horascalculadas + Number(Number(element.horashoraextra).toFixed(2))
           let TarifaCalculadas = (element.CostoHoraProfesional * HorasCalculadas) +((element.CostoHoraProfesional * element.horasferiado) * element.porcentajeferiado / 100)
           let HoraEntrada = element.HoraEntrada +""
           if (element.Fecha==="SUBTOTAL")
            {
              HoraSalida=" "
              HorasCalculadas=(totalhoras).toFixed(2)
              TarifaCalculadas= Math.round(totalhonorario)
              HoraEntrada=" "
            }
            let HC = (Number(HorasCalculadas)).toFixed(2)
          var temp = [element.Fecha,formatTime(HoraEntrada),element.FechaSalida,HoraSalida,HC,(Math.round(TarifaCalculadas)).toLocaleString('es'),element.Usuario];
          totalhoras += HorasCalculadas
          totalhonorario +=TarifaCalculadas 
          rows.push(temp);
          if (Object.is(arr.length - 1, key)) {
            var temp3 = ["SUBTOTAL"," ", " ","",(Number(totalhoras)).toFixed(2),Math.round(Sumatotal).toLocaleString('es')," "];
            rows.push(temp3);
          }
      });        


      gastos.forEach((element, key, arr) => {
        var temp2 = [element.gasto, element.monto ? element.monto.toLocaleString('es') : sumgastos.toLocaleString('es')];
        rowotros.push(temp2);
        if (Object.is(arr.length - 1, key)) {
          var temp3 = ["SUBTOTAL",`${sumgastos.toLocaleString('es')}`];
          rowotros.push(temp3);
        }
      })
 var pageNumber = doc.internal.getNumberOfPages()
  doc.text("OTROS SERVICIOS", 38, 72, null, null);
  doc.autoTable(colotros, rowotros, {
    startY: 74,
    showHead: 'firstPage',
    styles: { overflow: 'hidden' },
    margin: { right: 107 },
    willDrawCell: function (data) {
      var rows = data.table.body;
      if (data.row.index === rows.length - 1) {
        doc.setFont(undefined, "bold");
      }
    }
  })

  doc.setPage(pageNumber)
  doc.text("TOTAL", 140, 72, null, null,);
  doc.autoTable({
    columns: [
      { dataKey: 'Detalle', header: 'Detalle' },
      { dataKey: 'Monto', header: 'Monto' },
    ],
    body: Bodyrows(Sumatotal, sumgastos),
    startY: 74,
    showHead: 'firstPage',
    styles: { overflow: 'hidden' },
    margin: { left: 107 },
    willDrawCell: function (data) {
      var rows = data.table.body;
      if (data.row.index === rows.length - 1) {
        doc.setFont(undefined, "bold");
      }
    }
  })
  doc.text("MARCACIONES", xOffset, doc.lastAutoTable.finalY + 10, null, null, "center");
       doc.autoTable(col, rows, {
          startY: doc.lastAutoTable.finalY + 13, showHead: 'firstPage', margin: { top: 60, right: 10, bottom: 80, left: 10 }, willDrawCell: function (data) {
            var rows = data.table.body;
            if (data.row.index === rows.length - 1) {
              doc.setFont(undefined, "bold");
            }
          }
        });
      
   


        // Footer
       
        doc.setFontSize(10);
        var str = "Page " + doc.internal.getNumberOfPages()
        // Total page number plugin only available in jspdf v1.0+
        if (typeof doc.putTotalPages === 'function') {
          str = str + " of " + totalPagesExp;
        }
        doc.setFontSize(10);

        for (let pageNumber = 1; pageNumber <= doc.getNumberOfPages(); pageNumber++) {
          doc.setPage(pageNumber)
          doc.addImage(`${Headerimg}`, 'JPEG', 0, 0, 210,40);
           doc.addImage(`${Footerimg}`, 'JPEG', 0, 255, 210,40)
        }
   
    // Total page number plugin only available in jspdf v1.0+
    if (typeof doc.putTotalPages === 'function') {
      doc.putTotalPages(totalPagesExp);
    }
   
    doc.save(`${NombreUsuario} ${inicio} al ${fin}.pdf`);
  }
