import { getAuthToken } from "../context/auth-context";
import { CALL_API } from "../context/constValues";
import Swal from "sweetalert2";
export async function getAllLegajo() {
  const token = getAuthToken();
  const response = await fetch(`${CALL_API}/legajo`, {
    headers: { Authorization: "Bearer " + token },
  });

  const data = await response.json();

  if (!response.ok) {
    throw new Error("Can not fetch the data with this parameter");
  }

  return data.result;
}
export async function getAllLegajoActivos() {
  const token = getAuthToken();
  const response = await fetch(`${CALL_API}/legajo-activos`, {
    headers: { Authorization: "Bearer " + token },
  });

  const data = await response.json();

  if (!response.ok) {
    throw new Error("Can not fetch the data with this parameter");
  }

  return data.result;
}
export async function getAllLegajoConfirmados() {
  const token = getAuthToken();
  const response = await fetch(`${CALL_API}/legajo-confirmados`, {
    headers: { Authorization: "Bearer " + token },
  });

  const data = await response.json();

  if (!response.ok) {
    throw new Error("Can not fetch the data with this parameter");
  }

  return data.result;
}

export async function getAllLegajoByUsuario(id) {
  const token = getAuthToken();
  const response = await fetch(`${CALL_API}/legajo-usuario`, {
    headers: { Authorization: "Bearer " + token },
  });

  const data = await response.json();

  if (!response.ok) {
    throw new Error("Can not fetch the data with this parameter");
  }

  return data.result;
}

export async function getAllLegajoByOneUsuario(IdUsuario) {
  const token = getAuthToken();
  const response = await fetch(`${CALL_API}/legajo/usuario/${IdUsuario}`, {
    headers: { Authorization: "Bearer " + token },
  });

  const data = await response.json();

  if (!response.ok) {
    throw new Error("Can not fetch the data with this parameter");
  }

  return data.result;
}

export async function getLegajoById(idLegajo) {
  const token = getAuthToken();
  const response = await fetch(`${CALL_API}/legajo/${idLegajo}`, {
    headers: { Authorization: "Bearer " + token },
  });

  const data = await response.json();

  if (!response.ok) {
    throw new Error("Can not fetch the data with this parameter");
  }

  return data.result;
}

export async function getLegajoByData(consultaObjeto) {
  const token = getAuthToken();
  const { fechaInicio, fechaFin, idUsuario, idPaciente } = consultaObjeto;
  let callUrl = `${CALL_API}/consultarLegajo?fechaInicio=${fechaInicio}&fechaFin=${fechaFin}`;

  if (idUsuario && idUsuario !== "TODOS") {
    callUrl = `${callUrl}&idUsuario=${idUsuario}`;
  }

  if (idPaciente) {
    callUrl = `${callUrl}&idPaciente=${idPaciente}`;
  }

  const response = await fetch(callUrl, {
    headers: { Authorization: "Bearer " + token },
  });

  const data = await response.json();

  if (!response.ok) {
    throw new Error("Can not fetch the data with this parameter");
  }

  return data.result;
}
export async function getLegajoByDataCierre(consultaObjeto) {
  const token = getAuthToken();
  const { fechaInicio, fechaFin, idUsuario, idPaciente } = consultaObjeto;
  let callUrl = `${CALL_API}/consultarLegajoCierre?fechaInicio=${fechaInicio}&fechaFin=${fechaFin}`;

  if (idUsuario && idUsuario !== "TODOS") {
    callUrl = `${callUrl}&idUsuario=${idUsuario}`;
  }

  if (idPaciente) {
    callUrl = `${callUrl}&idPaciente=${idPaciente}`;
  }

  const response = await fetch(callUrl, {
    headers: { Authorization: "Bearer " + token },
  });

  const data = await response.json();

  if (!response.ok) {
    throw new Error("Can not fetch the data with this parameter");
  }

  return data.result;
}

export async function getLegajoActivo() {
  const token = getAuthToken();
  const response = await fetch(`${CALL_API}/activeLegajo`, {
    headers: { Authorization: "Bearer " + token },
  });

  const data = await response.json();

  if (!response.ok) {
    throw new Error("Can not fetch the data with this parameter");
  }

  return data.result;
}

export async function getLegajoByPaciente(idPaciente) {
  const token = getAuthToken();
  const response = await fetch(`${CALL_API}/legajo-paciente/${idPaciente}`, {
    headers: { Authorization: "Bearer " + token },
  });

  const data = await response.json();

  if (!response.ok) {
    throw new Error("Can not fetch the data with this parameter");
  }

  return data.result;
}
export async function getLegajoActivoByPaciente(idPaciente) {
  const token = getAuthToken();
  const response = await fetch(
    `${CALL_API}/legajoActivo-paciente/${idPaciente}`,
    {
      headers: { Authorization: "Bearer " + token },
    }
  );

  const data = await response.json();

  if (!response.ok) {
    throw new Error("Can not fetch the data with this parameter");
  }

  return data.result;
}

export async function getUltimosLegajosByCliente(idPaciente) {
  const token = getAuthToken();
  const response = await fetch(`${CALL_API}/legajo/${idPaciente}/ultimos`, {
    headers: { Authorization: "Bearer " + token },
  });

  const data = await response.json();

  if (!response.ok) {
    throw new Error("Can not fetch the data with this parameter");
  }

  return data.result;
}

export async function insertLegajo(legajoObject) {
  const token = getAuthToken();
  const {
    idPaciente,
    fecha,
    modalidad,
    horaEntrada,
    latitud,
    longitud,
    observacion,
    honorarioenfermero,
    tarifahora,
    guardiahs,
    infosalida,
  } = legajoObject;

  const response = await fetch(`${CALL_API}/legajo`, {
    method: "POST",
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      idPaciente: idPaciente,
      fecha: fecha,
      modalidad: modalidad,
      horaEntrada: horaEntrada,
      latitud: latitud,
      longitud: longitud,
      observacion: observacion,
      honorarioenfermero: honorarioenfermero,
      tarifahora: tarifahora,
      guardiahs: guardiahs,
      infosalida: infosalida,
    }),
  });

  const data = await response.json();
  if (!response.ok) {
    throw new Error(data.message);
  }

  return data.isSaved;
}

export async function confirmSalidaLegajo(marcacionObject) {
  const {
    fechaSalida,
    horaSalida,
    horascalculadas,
    totalhonorarioenfermero,
    totaltarifa,
    IdLegajoEnfermeria,
    latsalida,
    longsalida,
  } = marcacionObject;


  const token = getAuthToken();
  const response = await fetch(
    `${CALL_API}/confirm-legajo/${IdLegajoEnfermeria}`,
    {
      method: "PUT",
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        fechaSalida: fechaSalida,
        horaSalida: horaSalida,
        horascalculadas: horascalculadas,
        totalhonorarioenfermero: totalhonorarioenfermero,
        totaltarifa: totaltarifa,
        latsalida: latsalida,
        longsalida: longsalida,
      }),
    }
  );
  const data = await response.json();
  if (!response.ok) {
    throw new Error(data.message);
  }

  return data.isConfirmed;
}
export async function LegajoHoraExtra(marcacionObject) {
  const { horaextra, cantidadhoraextra, IdLegajoEnfermeria, horaextramaximo } =
    marcacionObject;
  const token = getAuthToken();
  const response = await fetch(`${CALL_API}/legajo-horaextra`, {
    method: "PUT",
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      horaextra: horaextra,
      cantidadhoraextra: cantidadhoraextra,
      IdLegajoEnfermeria: IdLegajoEnfermeria,
      horaextramaximo: horaextramaximo,
    }),
  });
  const data = await response.json();
  if (!response.ok) {
    throw new Error(data.message);
  }

  return data.isSaved;
}
export async function deleteLegajo(idLegajo) {
  const token = getAuthToken();
  const response = await fetch(`${CALL_API}/legajo/${idLegajo}`, {
    method: "DELETE",
    headers: { Authorization: "Bearer " + token },
  });

  const data = await response.json();

  if (!response.ok) {
    throw new Error("Can not fetch the data with this parameter");
  }

  return { ...data };
}
export async function GenerarLiquidacion(objeto) {
  const token = getAuthToken();
  const { idp, Valor } = objeto;
  const response = await fetch(
    `${CALL_API}/GenerarLiquidacion?IdPaciente=${idp}&Fechafinal=${Valor}`,
    {
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    }
  );

  const data = await response.json();

  if (!response.ok) {
    throw new Error("Can not fetch the data with this parameter");
  }

  return data.result;
}
export async function GuardarLiquidacion(legajoObject) {
  const token = getAuthToken();
  const { obs, fin, ini, idPaciente } =
    legajoObject;
  const response = await fetch(`${CALL_API}/GuardarLiquidacion`, {
    method: "PUT",
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      obs: obs,
      fechainicio: ini,
      fechafin: fin,
      IdPaciente: idPaciente,
    }),
  });
  const data = await response.json();
  if (!response.ok) {
    Swal.fire({
      title: "Error!",
      text: `${data.message}`,
      icon: "error",
      confirmButtonText: "Ok",
    });

  }
  return data.result[0];
}
export async function MarcarLegajoLiquidacion(Objeto) {
  const { IDliquidacion, idPaciente, fin, ini } = Objeto;
  const token = getAuthToken();
  const response = await fetch(`${CALL_API}/MarcarLegajoLiquidacion`, {
    method: "PUT",
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      IDliquidacion: IDliquidacion,
      IDPaciente: idPaciente,
      fechainicio: ini,
      fechafin: fin,
    }),
  });

  const data = await response.json();


  if (!response.ok) {
    Swal.fire({
      title: "Error!",
      text: `${data.message}`,
      icon: "error",
      confirmButtonText: "Ok",
    });

  }
  return data.result;
}
export async function GetliquidacionPaciente(IdPacientes) {
  const token = getAuthToken();
  const response = await fetch(
    `${CALL_API}/LiquidacionPaciente/${IdPacientes}`,
    {
      headers: { Authorization: "Bearer " + token },
    }
  );

  const data = await response.json();

  if (!response.ok) {
    throw new Error("Can not fetch the data with this parameter");
  }
  return data.result;
}
// Editar Legajo para Liquidacion
export async function UpdateObs(objeto) {
  const {
    IDLEGAJO,
    obs
  } = objeto;
  const token = getAuthToken();
  const response = await fetch(`${CALL_API}/updateobs`, {
    method: "PUT",
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      idLegajo: IDLEGAJO,
      obs: obs
    }),
  });
  const data = await response.json();
  if (!response.ok) {
    throw new Error("Can not fetch the data with this parameter");
  }

  return data.isSaved;
}
export async function EditLegajo(objeto) {
  const {
    IdLegajoEnfermeria,
    Fecha,
    FechaSalida,
    HoraEntrada,
    HoraSalida,
    totalhs,
    CostoHoraProfesional,
    CostoHoraEmpresa,
    paraHonorario,
    paraTarifa
  } = objeto;
  const token = getAuthToken();
  const response = await fetch(`${CALL_API}/updatelegajo`, {
    method: "PUT",
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      idLegajo: IdLegajoEnfermeria,
      fechaInicio: Fecha,
      fechafin: FechaSalida,
      horainicio: HoraEntrada,
      horafin: HoraSalida,
      totalhs: totalhs,
      tarifa : CostoHoraEmpresa,
      honorario: CostoHoraProfesional,
      paraHonorario:paraHonorario,
      paraTarifa:paraTarifa

    }),
  });
  const data = await response.json();
  if (!response.ok) {
    throw new Error("Can not fetch the data with this parameter");
  }

  return data.isSaved;
}
export async function EditFeriado(objeto) {
  const {
    IdLegajoEnfermeria,
    horasferiado,
    porcentajeferiado
  } = objeto;
  const token = getAuthToken();
  const response = await fetch(`${CALL_API}/feriadolegajo`, {
    method: "PUT",
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      idLegajo: IdLegajoEnfermeria,
      horasferiado:horasferiado,
      porcentajeferiado:porcentajeferiado
    }),
  });
  const data = await response.json();
  if (!response.ok) {
    throw new Error("Can not fetch the data with this parameter");
  }

  return data.isSaved;
}
export async function AddLegajo(objeto) {
  const {
    idPaciente,
    idUsuario,
    paciente,
    fechaInicio,
    fechafin,
    horainicio,
    horafin,
    totalhs,
    totaltarifa,
    usuario,
    tarifa,
    honorario,
    paraTarifa,
    paraHonorario,
    idliquidacion,
    totalhonorario
  } = objeto;
  const token = getAuthToken();
  const response = await fetch(`${CALL_API}/AddLegajo`, {
    method: "POST",
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      
      idPaciente,
      idUsuario,
      paciente,
      fechaInicio,
      fechafin,
      horainicio,
      horafin,
      totalhs,
      totaltarifa,
      usuario,
      tarifa,
      totalhonorario, //nuevo
      honorario, //nuevo
      paraTarifa, //nuevo
      paraHonorario, //nuevo
      idliquidacion,//nuevo

    }),
  });
  const data = await response.json();
  if (!response.ok) {
    throw new Error("Can not fetch the data with this parameter");
  }

  return data.isSaved;
}
export async function getliquidacionbyid(id) {
  const token = getAuthToken();
  const response = await fetch(`${CALL_API}/getliquidacion/${id}`, {
    headers: { Authorization: "Bearer " + token },
  });

  const data = await response.json();

  if (!response.ok) {
    throw new Error("Can not fetch the data with this parameter");
  }

  return data.result;
}
export async function getlegajosbyliquidacion(id) {
  const token = getAuthToken();
  const response = await fetch(`${CALL_API}/legajobyliquidacion/${id}`, {
    headers: { Authorization: "Bearer " + token },
  });

  const data = await response.json();
  if (!response.ok) {
    throw new Error("Can not fetch the data with this parameter");
  }

  return data.result;
}
export async function liquidarliquidacion(objeto) {
  const { idliquidacion, facturanumero, montoliquidado, obs,estado} = objeto;
  const token = getAuthToken();
  const response = await fetch(`${CALL_API}/liquidarliquidacion`, {
    method: "PUT",
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      idliquidacion: idliquidacion,
      numerofactura: facturanumero,
      montoliquidado: montoliquidado,
      obs: obs,
      estado:estado
    }),
  });
  const data = await response.json();
  if (!response.ok) {
    throw new Error("Can not fetch the data with this parameter");
  }

  return data.isConfirmed;
}
export async function getAllLiquidaciones() {
  const token = getAuthToken();
  const response = await fetch(`${CALL_API}/liquidaciones`, {
    headers: { Authorization: "Bearer " + token },
  });

  const data = await response.json();

  if (!response.ok) {
    throw new Error("Can not fetch the data with this parameter");
  }

  return data.result;
}
export async function InsertRecibo(Objeto) {
  const token = getAuthToken();
  const {
    nombrepaciente, nombreusuario,Sumatotal, iduser, id
  } = Objeto

  const response = await fetch(`${CALL_API}/recibo`, {
    method: "POST",
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      nombrepaciente: nombrepaciente,
      nombreusuario: nombreusuario,
      Honorario: Sumatotal,
      idusuario: iduser,
      idliquidacion: id
    }),
  });

  const data = await response.json();
  if (!response.ok) {
    throw new Error(data.message);
  }

  return data.isSaved;
}
export async function getidrecibo(idusuario, idrecibo) {
  const token = getAuthToken();
  const response = await fetch(
    `${CALL_API}/buscar/recibo?idusuario=${idusuario}&idrecibo=${idrecibo}`,
    {
      headers: { Authorization: "Bearer " + token },
    }
  );

  const data = await response.json();

  if (!response.ok) {
    throw new Error("Can not fetch the data with this parameter");
  }

  return data.result;
}
export async function AddGasto(objeto) {
  const {gasto,idPaciente,idUsuario,idliquidacion,monto,paciente,paraHonorario,paraTarifa,usuario} = objeto
  const token = getAuthToken();
  const response = await fetch(`${CALL_API}/AddGasto`, {
    method: "POST",
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      gasto,
      idPaciente,
      idUsuario,
      idliquidacion,
      monto,
      paciente,
      paraHonorario,
      paraTarifa,
      usuario
    }),
  });
  const data = await response.json();
  if (!response.ok) {
    throw new Error("Can not fetch the data with this parameter");
  }

  return data.isSaved;
}
export async function getgastosbyliquidacion(id) {
  const token = getAuthToken();
  const response = await fetch(`${CALL_API}/gastosbyusuario/${id}`, {
    headers: { Authorization: "Bearer " + token },
  });

  const data = await response.json();
  if (!response.ok) {
    throw new Error("Can not fetch the data with this parameter");
  }

  return data.result;
}
export async function EditGasto(objeto) {
  const {gasto,idUsuario,monto,paraHonorario,paraTarifa,usuario,idgastoenfermeria} = objeto
  const token = getAuthToken();
  const response = await fetch(`${CALL_API}/EditGasto`, {
    method: "POST",
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      idgastoenfermeria,
      gasto,
      monto,
      paraHonorario,
      paraTarifa,
      usuario,
      idUsuario,

    }),
  });
  const data = await response.json();
  console.log(data)
  if (!response.ok) {
    throw new Error("Can not fetch the data with this parameter");
  }

  return data.isSaved;
}