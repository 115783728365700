import Swal from "sweetalert2";
import { useLocation } from "react-router-dom";
import { Button, Table } from "react-bootstrap";
import React, { useState } from "react";
import { useHistory } from 'react-router-dom';
import { getLegajoByDataCierre, GuardarLiquidacion, MarcarLegajoLiquidacion } from "../../../api/marcacionApi.js";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { AiOutlineIssuesClose } from "react-icons/ai";
const Cierre = () => {
  const history = useHistory();
  const [fechaInicio, setfechaInicio] = useState();
  const [fechaFin, setfechaFin] = useState();
  const [ini, setIni] = useState();
  const [fin, setFin] = useState();
  const [data, setData] = useState([]);
  const location = useLocation();
  const items = location.state
  const _ = require("lodash");
  const handleCierre = async () => {
    try {
      Swal.fire({
        title: "Cargando Datos",
        allowOutsideClick: false,
        showCancelButton: false,
        showConfirmButton: false,
      });
      // loading button 
      // traer los legajos que tengan vacio o  NULL en idliquidacion y del paciente
      let idPaciente = items.IdPacientes
      if (idPaciente === undefined || fechaInicio === undefined || fechaFin === undefined) {
        Swal.fire({
          title: 'Paciente o Fecha Invalida',
          allowOutsideClick: false,
          showCancelButton: false,
          showCloseButton: false,
          showConfirmButton: true,
        });
      } else {
        let idUsuario = "TODOS"
        const datass = await getLegajoByDataCierre({ fechaInicio, fechaFin, idUsuario, idPaciente })
        const datas = _.filter(datass, (rowsDatas) => rowsDatas.IdLiquidacion==null)
        if (datas.length === 0) {
          Swal.fire({
            title: 'No se Encontraron Datos',
            allowOutsideClick: false,
            showCancelButton: false,
            showCloseButton: false,
            showConfirmButton: true,
          });
          setData([...datas])
          
        } else {
          setData([...datas])
          setIni(fechaInicio)
          setFin(fechaFin)
          Swal.close();
        }
      }
    } catch (err) {
      Swal.close();
      console.log(err);
    }
  };
  const handleGuardar = async () => {
    try {
      Swal.fire({
        title: "Cargando Datos",
        allowOutsideClick: false,
        showCancelButton: false,
        showConfirmButton: false,
      });
      // loading button 
      // traer los legajos que tengan vacio o  NULL en idliquidacion y del paciente
      let idPaciente = items.IdPacientes
      if (idPaciente === undefined || ini === undefined || fin === undefined || data.length === 0) {
        Swal.fire({
          title: 'Paciente o Fecha Invalida',
          allowOutsideClick: false,
          showCancelButton: false,
          showCloseButton: false,
          showConfirmButton: true,
        });
      } else {
        
        const { value: text } = await Swal.fire({
          input: "textarea",
          inputLabel: "Observacion",
          inputPlaceholder: "",
          inputAttributes: {
            "aria-label": "Type your message here"
          },
          showCancelButton: true,
          inputValidator: (value) => {
            if (!value) {
              return "Ingrese Observacion";
            }
          }
        })
        if (text)
        {

          let obs = text? text:"SIN OBS.";
          const ID = await GuardarLiquidacion({ obs, fin, ini, idPaciente })
          let IDliquidacion = ID.idLiquidacionClientes
          const response = await MarcarLegajoLiquidacion({ IDliquidacion, idPaciente, fin, ini })
          history.push({
            pathname: "/clientes",
          });
          
        }
        
      }
    } catch (err) {
      Swal.close();
      console.log(err);
    }
  };
  return (
    <>
      <div key={items.IdPacientes}>
        <div className="col-xl-12">
          <div className="page-titles acti-space">
            <h2 className="heading sm-mb-0 mb-2">{items.NombrePaciente}</h2>
            <div className="d-flex align-items-center flex-wrap">
              <div className="me-2">
              </div>
            </div>
          </div>
        </div>
        <div className="">

          <div className="col-xl-12">
            <div className="card">
              <div className="card-header">
                <h4 className="card-title">Cierres</h4>
                <div className="d-flex align-items-center">
                  <label className="p-3">fecha inicio</label>
                  <input
                    type="date"
                    className="form-control p-3"
                    onChange={(e) => {
                      setfechaInicio(e.target.value);
                    }}
                  />
                  <label className="p-3">fecha fin</label>
                  <input
                    type="date"
                    className="form-control p-3 m-2"
                    onChange={(e) => {
                      setfechaFin(e.target.value);
                    }}
                  />
                  <Button className="form-control p-3" onClick={handleCierre}>Generar cierre</Button>
                </div>
              </div>
              <div className="card-body p-4">
                <Table responsive className="primary-table-bordered" style={{ borderRadius: '10px', overflow: 'hidden' }} striped hover={true}  >
                  <thead className="thead-primary" >
                    <tr>
                      <th style={{
                        whiteSpace: "pre-wrap",
                        overflowWrap: "break-word",
                      }} scope="col">Cuenta</th>
                      <th style={{
                        whiteSpace: "pre-wrap",
                        overflowWrap: "break-word",
                      }} scope="col">Fecha Entrada</th>
                      <th style={{
                        whiteSpace: "pre-wrap",
                        overflowWrap: "break-word",
                      }} scope="col">Hora Entrada</th>
                      <th style={{
                        whiteSpace: "pre-wrap",
                        overflowWrap: "break-word",
                      }} scope="col">Fecha Salida</th>
                      <th style={{
                        whiteSpace: "pre-wrap",
                        overflowWrap: "break-word",
                      }} scope="col">hora Salida</th>
                      <th style={{
                        whiteSpace: "pre-wrap",
                        overflowWrap: "break-word",
                      }} scope="col">Horas Extras</th>
                      <th style={{
                        whiteSpace: "pre-wrap",
                        overflowWrap: "break-word",
                      }} scope="col">Horas Calculadas</th>
                      <th style={{
                        whiteSpace: "pre-wrap",
                        overflowWrap: "break-word",
                      }} scope="col">Honorario total</th>
                       <th style={{
                        whiteSpace: "pre-wrap",
                        overflowWrap: "break-word",
                      }} scope="col">Obs.</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.map((item, index) => (
                      <tr key={index} >
                        <td>{item.Cuenta}</td>
                        <td>{item.FechaEntrada}</td>
                        <td>{item.HoraEntrada}</td>
                        <td>{item.FechaSalida}</td>
                        <td>{item.HoraSalida}</td>
                        <td>{item.Horaextra}</td>
                        <td>{item.HorasCalculadas}</td>
                        <td>{item.HonorarioTotal}</td>
                        <td><OverlayTrigger
                            placement="bottom"
                            overlay={<Tooltip id="button-tooltip-2">{item.Observacion}</Tooltip>}>
                              <i
                            className="material-icons"
                            style={{ height:"100" }}
                          >
                           <AiOutlineIssuesClose />
                          </i>
                        
                           </OverlayTrigger></td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                <Button onClick={handleGuardar}>Guardar</Button>
              </div>

            </div>

          </div>
        </div>
      </div>
    </>
  );
};

export default Cierre;
