import { Button, Modal } from "react-bootstrap";
import React, { useCallback, useEffect, useState, useRef } from "react";
import BaseSelect from "react-select";
import FixRequiredSelect from "../../components/sis/modalcliente/FixRequiredSelect";
import { getAllUsuario } from "../../../api/usuarioApi";
import Swal from "sweetalert2";
const options = [
  { value: 1, label: "1 - One" },
  { value: 2, label: "2 - Two" },
  { value: 3, label: "3 - Three" },
];
const Select = (props) => (
  <FixRequiredSelect
    {...props}
    SelectComponent={BaseSelect}
    options={props.options || options}
  />
);
const ModalEditGastos = (props) => {
    const { estado, onCloseModal, onReturnValue,rowdata} = props;
  const [listUsuarios, setListUsuario] = useState([]);
  const [monto, setmonto] = useState(rowdata.monto);
  const addCommas = num => num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  const removeNonNumeric = num => num.toString().replace(/[^0-9,\-]/g,'')
  const handleChange = event =>
    setmonto(addCommas(removeNonNumeric(event.target.value)));

  const assigmentValue = useCallback(async () => {
    const resultUsuario = await getAllUsuario();
    let filterNumbers = resultUsuario.filter(num => num.estaActivo !== 'Inactivo');
    const resultMapeado = filterNumbers.map((item) => {
      {
        return { label: item.Usuario, value: item.IdUsuario };
      }
    });
    const newList = [{ label: "Otros", value: 0 }, ...resultMapeado];
    setListUsuario(newList);
  }, []);

  useEffect(() => {
    assigmentValue();
  }, [assigmentValue]);
 
  const [formData3, setValue] = useState(rowdata);
  const handleSubmit2 = (event) => {
    formData3.paraTarifa=formData3.paraTarifa? formData3.paraTarifa:"SI"
    formData3.paraHonorario=formData3.paraHonorario? formData3.paraHonorario:"SI"
    formData3.monto=formData3.monto? parseInt(removeNonNumeric(monto)):parseInt(removeNonNumeric(monto))
    event.preventDefault();
   if (formData3.idUsuario === undefined) {
      Swal.fire({
        title: "Seleccione Profesional",
        icon: "error",
      });
    } else if ((formData3.gasto === undefined)) {
      Swal.fire({
        title: "Ingrese nombre del gasto",
        icon: "error",
      });
    }
      else if ((formData3.monto === undefined || formData3.monto===0))
      {
        Swal.fire({
          title: "Monto Incorrecto",
          icon: "error",
        });
      }else
      {
       onReturnValue(formData3)
      }
  };
  return (
    <>
      <Modal show={estado} onHide={onCloseModal} size="m">
        <Modal.Header closeButton>
          <Modal.Title>Editar Gastos</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form  >

            <div className="form-group mb-2 row">
              <label className="col-form-label">
                Profesional
                <span className="text-danger">*</span>
              </label>
              <Select
                options={listUsuarios}
                isSearchable
                defaultValue={{ label:formData3.usuario, value: formData3.idUsuario }}
                onChange={(e) => {
                  setValue({
                    ...formData3,
                    idUsuario: e.value,
                    usuario: e.label,
                  });

                }}
              />
            </div>
            <div className="form-group mb-2 col">

              <label className="col-form-label">
                Detalle
                <span className="text-danger">*</span>
              </label>
              <div className="">
                <input
                  type="text"
                  className="form-control"
                  value={formData3.gasto}
                  onChange={(e) => {
                    setValue({
                      ...formData3,
                      gasto: e.target.value,
                    });
                  }}
                />
              </div>
            </div>
            <div className="form-group mb-2 col">

              <label className="col-form-label">
                Monto
                <span className="text-danger">*</span>
              </label>
              <div className="">
                <input
                  type="text" value={monto} onInput={handleChange} 
                   className="form-control"
                />
              </div>
            </div>
            <div className="form-group mb-2 row">
              <div className="col">
                <label className="col-form-label">
                  Para Tarifa?
                </label>
                <div className="">
                  <select name="paraTarifa" className="form-control" value={formData3.paraTarifa} onChange={(e) => {
                    setValue({
                      ...formData3,
                      [e.target.name]: e.target.value,
                    })
                  }}>
                    <option value="SI">Si</option>
                    <option value="NO">No</option>
                  </select>
                </div>
              </div>
              <div className="col">
                <label className="col-form-label">
                  Para Honorario?
                </label>
                <div className="">
                  <select name="paraHonorario" className="form-control"  value={formData3.paraHonorario} onChange={(e) => {
                    setValue({
                      ...formData3,
                      [e.target.name]: e.target.value,
                    })
                  }}>
                    <option value="SI">Si</option>
                    <option value="NO">No</option>
                  </select>
                </div>
              </div>

            </div>


          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={onCloseModal}>
            Cerrar
          </Button>
          <Button onClick={handleSubmit2} form="userform">
            Guardar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ModalEditGastos;