import { Button, Modal } from "react-bootstrap";
import React, { useCallback, useEffect, useState, useRef } from "react";
import BaseSelect from "react-select";
import FixRequiredSelect from "../../components/sis/modalcliente/FixRequiredSelect";
import { getAllUsuario } from "../../../api/usuarioApi";
import Swal from "sweetalert2";
const options = [
  { value: 1, label: "1 - One" },
  { value: 2, label: "2 - Two" },
  { value: 3, label: "3 - Three" },
];
const Select = (props) => (
  <FixRequiredSelect
    {...props}
    SelectComponent={BaseSelect}
    options={props.options || options}
  />
);
const ModalLegajo = (props) => {
  const [listUsuarios, setListUsuario] = useState([]);
  const assigmentValue = useCallback(async () => {
    const resultUsuario = await getAllUsuario();
    let filterNumbers = resultUsuario.filter(num => num.estaActivo !== 'Inactivo');
    const resultMapeado = filterNumbers.map((item) => {
      {
        return { label: item.Usuario, value: item.IdUsuario };
      }
    });
    setListUsuario(resultMapeado);
  }, []);

  useEffect(() => {
    assigmentValue();
  }, [assigmentValue]);
  const { estado, onCloseModal, onReturnValue, isNew } = props;
  const [formData3, setValue] = useState([]);
  const [totalhs, settotalhs] = useState(0);
  const [totalhonorario, settotalhonorario] = useState(0);
  const [totaltarifa, settotaltarifa] = useState(0);
  const CalcularHorasTotal = (e) => {
    let fechain
    let fechafi
    let horain
    let horafi
    setValue({
      ...formData3,
      [e.target.name]: e.target.value,
    })
    if (e.target.name === "fechaInicio") {
      fechain = e.target.value
      fechafi = formData3.fechafin
      horain = formData3.horainicio
      horafi = formData3.horafin
    } else if ((e.target.name ==="horainicio")) {
      fechain = formData3.fechaInicio
      fechafi = formData3.fechafin
      horain = e.target.value
      horafi = formData3.horafin
    } else if ((e.target.name === "fechafin")) {
      fechain = formData3.fechaInicio
      fechafi = e.target.value
      horain = formData3.horainicio
      horafi = formData3.horafin
    } else if ((e.target.name === "horafin")) {
      fechain = formData3.fechaInicio
      fechafi = formData3.fechafin
      horain = formData3.horainicio
      horafi = e.target.value
    }
    var fecha1 = new Date(fechain + " " + horain);
    var fecha2 = new Date(fechafi + " " + horafi);
    let diff = (fecha2 - fecha1) / 3600000;
    let num = Number(diff.toFixed(2))
    settotalhs(num)
    settotalhonorario(Math.round(num*formData3.honorario))
    settotaltarifa(Math.round(num*formData3.tarifa))
  };
  const CalcularTotalHonorario = (e) => {
    let totalhonorario1
    setValue({
      ...formData3,
      [e.target.name]: e.target.value,
    })
    if (e.target.name === "honorario") {

      totalhonorario1 = totalhs * e.target.value
    }
    settotalhonorario(Math.round(totalhonorario1))
  };
  const CalcularTotalTarifa = (e) => {
    let totaltarifa1
    setValue({
      ...formData3,
      [e.target.name]: e.target.value,
    })
    if (e.target.name === "tarifa") {
      totaltarifa1 = totalhs * e.target.value
    }
    settotaltarifa(Math.round(totaltarifa1))
  };
  const handleSubmit2 = (event) => {
    formData3.paraTarifa=formData3.paraTarifa? formData3.paraTarifa:"SI"
    formData3.paraHonorario=formData3.paraHonorario? formData3.paraHonorario:"SI"
    formData3.totalhs=formData3.totalhs? formData3.totahs:totalhs
    formData3.totaltarifa=formData3.totaltarifa? formData3.totaltarifa:totaltarifa
    formData3.totalhonorario=formData3.totalhonorario?formData3.totalhonorario:totalhonorario
    event.preventDefault();
    if (formData3.idUsuario===undefined)
    {
     Swal.fire({
               title: "Seleccione Profesional",
               icon: "error",
             });
    }else if(totalhs===0 || isNaN(totalhs))
    {
      Swal.fire({
        title: "Error de horario",
        icon: "error",
      });
    }else if (totaltarifa===0 || isNaN(totaltarifa))
    {
      Swal.fire({
        title: "Error de Tarifa",
        icon: "error",
      });
    }else if (totalhonorario===0 || isNaN(totalhonorario))
      {
        Swal.fire({
          title: "Error de Honorario",
          icon: "error",
        });
      }
      else 
      {
        onReturnValue(formData3);
      }
  };
  return (
    <>
      <Modal show={estado} onHide={onCloseModal} size="m">
        <Modal.Header closeButton>
          <Modal.Title>Agregar Marcacion</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form  >

            <div className="form-group mb-2 row">
              <label className="col-form-label">
                Profesional
                <span className="text-danger">*</span>
              </label>
              <Select
                options={listUsuarios}
                isSearchable
                required
                onChange={(e) => {
                  setValue({
                    ...formData3,
                    idUsuario: e.value,
                    usuario: e.label,
                  });

                }}
              />
            </div>
            <div className="row">
              <div className="form-group mb-2 col">

                <label className="col-form-label">
                  Fecha Entrada
                  <span className="text-danger">*</span>
                </label>
                <div className="">
                  <input
                    type="date"
                    className="form-control"
                    required
                    name="fechaInicio"
                    onChange={(e) => {
                      CalcularHorasTotal(e);
                    }}
                  />
                </div>
              </div>
              <div className="form-group mb-2 col">
                <label className="col-form-label">
                  Hora Entrada
                  <span className="text-danger">*</span>
                </label>
                <div className="">
                  <input
                    type="time"
                    className="form-control"
                    required
                    name="horainicio"
                    step="1"
                    onChange={(e) => {
                      CalcularHorasTotal(e);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="form-group mb-2 col">

                <label className="col-form-label">
                  Fecha Salida
                  <span className="text-danger">*</span>
                </label>
                <div className="">
                  <input
                    type="date"
                    className="form-control"
                    required
                    name="fechafin"
                    
                    onChange={(e) => {
                      CalcularHorasTotal(e);
                    }}
                  />
                </div>
              </div>
              <div className="form-group mb-2 col">
                <label className="col-form-label">
                  Hora Salida
                  <span className="text-danger">*</span>
                </label>
                <div className="">
                  <input
                    type="time"
                    className="form-control"
                    required
                    name="horafin"
                    step="1"
                    onChange={(e) => {
                      CalcularHorasTotal(e);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="form-group mb-2 col">
                <label className="col-form-label">
                  Tarifa
                  <span className="text-danger">*</span>
                </label>
                <div className="">
                  <input
                    type="number"
                    className="form-control"
                    required
                    name="tarifa"
                    onChange={(e) => {
                      CalcularTotalTarifa(e)
                    }}
                  />
                </div>
              </div>
              <div className="form-group mb-2 col">
                <label className="col-form-label">
                  Honorario
                  <span className="text-danger">*</span>
                </label>
                <div className="">
                  <input
                    type="number"
                    className="form-control"
                    required
                    name="honorario"
                    onChange={(e) => {
                      CalcularTotalHonorario(e);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="form-group mb-2 col">
                <label className="col-form-label">
                  Tarifa Total
                </label>
                <div className="">
                  <input
                    type="number"
                    className="form-control"
                    value={totaltarifa}
                    disabled
                  />
                </div>
              </div>
              <div className="form-group mb-2 col">
                <label className="col-form-label">
                  Honorario Total
                </label>
                <div className="">
                  <input
                    type="number"
                    className="form-control"
                    value={totalhonorario}
                    disabled
                  />
                </div>
              </div>
              <div className="form-group mb-2 col">
                <label className="col-form-label">
                  Hora Total
                </label>
                <div className="">
                  <input
                    type="number"
                    className="form-control"
                    value={totalhs}
                    disabled
                  />
                </div>
              </div>
            </div>
            <div className="form-group mb-2 row">
              <div className="col">
                <label className="col-form-label">
                  Para Tarifa?
                </label>
                <div className="">
                  <select name="paraTarifa" className="form-control"  onChange={(e) => {
                      setValue({
                        ...formData3,
                        [e.target.name]: e.target.value,
                      })
                    }}>
                    <option value="SI">Si</option>
                    <option value="NO">No</option>
                  </select>
                </div>
              </div>
              <div className="col">
                <label className="col-form-label">
                  Para Honorario?
                </label>
                <div className="">
                  <select  name="paraHonorario" className="form-control"   onChange={(e) => {
                      setValue({
                        ...formData3,
                        [e.target.name]: e.target.value,
                      })
                    }}>
                    <option value="SI">Si</option>
                    <option value="NO">No</option>
                  </select>
                </div>
              </div>

            </div>


          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={onCloseModal}>
            Cerrar
          </Button>
          <Button onClick={handleSubmit2} form="userform">
            Guardar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ModalLegajo;
